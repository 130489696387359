export default {
    marginTitle: {
        marginTop: 8,
        marginBottom: 24,
    },
    marginInput: {
        marginTop: 8,
        marginBottom: 24,
    },
    container: {
        flex: 1,
        paddingTop: 24,
        paddingRight: 16,
        paddingLeft: 16,
        backgroundColor: '#fff',
    },
    contentCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    displayRowCenter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    displayRowBetween: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    displayRowAround: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'flex-end',
    },
    displayRowEvenly: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    displayRowStart: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    displayRowEnd: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    button: {
        height: 48,
        fontSize: 32,
    },
    inputError: {
        color: '#d62828',
        fontSize: 16,
    },
    wrapperInput: {
        marginTop: 8,
        marginBottom: 24,
    },
    marginTopBottom24: {
        marginTop: 24,
        marginBottom: 24,
    },
    marginTopBottom16: {
        marginTop: 16,
        marginBottom: 16,
    },
    marginTopBottom8: {
        marginTop: 8,
        marginBottom: 8,
    },
    textCenter: {
        textAlign: 'center',
    },
};
