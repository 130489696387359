import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import Loader from '../common/loader';
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import api from '../../config/axios';
import utils from '../../utils/miscUtils';
import { useStateValue } from '../../store';

const { makePasswordRandom } = utils;

const NewClient = withRouter(({ history }) => {
    const { handleSubmit, register, errors, setValue, getValues } = useForm();
    const [loaded, setLoaded] = useState(false);
    const [settings, setSettings] = useState(null);
    const [{ auth }] = useStateValue();
    const { franchiseId } = auth.user;
    const [map, setMap] = useState(null);

    // Fetchs info when component is mounted
    useEffect(() => {
        //register({ name: 'location' }, { required: true });
        Promise.all([fetchSettings(franchiseId)]).then((values) => {
            setSettings(values[0]);
            setLoaded(true);

            // Ask for geolocation permit
            navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
                if (result.state === 'granted') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position, error, options) => {
                            if (position) {
                                initGoogleMap(position.coords.latitude, position.coords.longitude);
                            } else {
                                initGoogleMap(
                                    values[0][0].referencePoint.coordinates[1],
                                    values[0][0].referencePoint.coordinates[0],
                                );
                            }
                        });
                    } else {
                        initGoogleMap(
                            values[0][0].referencePoint.coordinates[1],
                            values[0][0].referencePoint.coordinates[0],
                        );
                    }
                } else if (result.state === 'prompt') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((position, error, options) => {
                            if (position) {
                                initGoogleMap(position.coords.latitude, position.coords.longitude);
                            } else {
                                initGoogleMap(
                                    values[0][0].referencePoint.coordinates[1],
                                    values[0][0].referencePoint.coordinates[0],
                                );
                            }
                        });
                    } else {
                        initGoogleMap(
                            values[0][0].referencePoint.coordinates[1],
                            values[0][0].referencePoint.coordinates[0],
                        );
                    }
                } else {
                    initGoogleMap(
                        values[0][0].referencePoint.coordinates[1],
                        values[0][0].referencePoint.coordinates[0],
                    );
                }
            });
        });
    }, []);

    const initGoogleMap = (lat, lng) => {
        let map = new window.google.maps.Map(document.getElementById(`map`), {
            center: {
                lat: lat,
                lng: lng,
            },
            zoom: 17,
            mapTypeControl: false,
            streetViewControl: false,
        });
        const marker = new window.google.maps.Marker({
            position: {
                lat: lat,
                lng: lng,
            },
            map,
        });

        map.addListener('center_changed', () => {
            var newLatLng = new window.google.maps.LatLng(map.center.lat(), map.center.lng());

            marker.setPosition(newLatLng);
        });
        setMap(map);
    };

    const fetchSettings = async () => {
        // consulta los settings dependiendo del usuario logueado
        // si está logueado un usuario FRANQUICIA trae sólo los settings de ese usuario
        // si está logueado un usuario ADMIN trae todos los settings de la DB

        let url = !!franchiseId ? `/franchise/${franchiseId}/settings` : '/settings';
        const { data } = await api.get(url);
        const settings = data.data;

        return settings;
    };

    const handleNewClient = (data) => {
        const dataClient = {
            ...data,
            phone_number: Number(data.phone_number.replace('0', '58')), //.replace() in javascript only replaces the first occurrence by default
            auth_type: 'local',
            password: makePasswordRandom(),
            requestSource: 'admin',
        };

        api.post('/client/signup', dataClient)
            .then((response) => {
                const clientId = response.data.data.client.id;
                const dataAddress = {
                    street1: data.street1,
                    street2: data.street2,
                    house_number: 'N/A',
                    latitude: map?.center.lat(),
                    longitude: map?.center.lng(),
                };
                api.post(`/client/${clientId}/address`, dataAddress)
                    .then(() => {
                        toast.success('Cliente agregado con éxito');
                        history.push(`/clientes/${clientId}`);
                    })
                    .catch((error) => {
                        toast.error('Hubo un error al agregar la dirección. Intente de nuevo');
                    });
            })
            .catch((error) => {
                toast.error('Hubo un error al agregar el cliente. Intente de nuevo');
            });
    };

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    return (
        <Fragment>
            <Breadcrumb title="Nuevo Cliente" parent={null} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Datos personales</h5>
                            </div>
                            <div className="card-body">
                                <div className="row product-adding">
                                    <div className="col-xl-10 offset-xl-1">
                                        <div className="needs-validation add-product-form">
                                            <div className="form form-label-center">
                                                <div className="form-group mb-3 row">
                                                    <label
                                                        htmlFor="name"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Nombre Completo:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            placeholder="Nombre y Apellido"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.name &&
                                                            errors.name.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Nombre es obligatorio
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label
                                                        htmlFor="name"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Número de teléfono:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Ejm: 04147353821"
                                                            name="phone_number"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.phone_number &&
                                                            errors.phone_number.type ===
                                                                'required' && (
                                                                <p className="message-error-input">
                                                                    Número de teléfono es
                                                                    obligatorio
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label
                                                        htmlFor="email"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Email:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="romer@ejemplo.com"
                                                            name="email"
                                                            ref={register({
                                                                required: true,
                                                                pattern:
                                                                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
                                                            })}
                                                        />
                                                        {errors.email &&
                                                            errors.email.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Email es obligatorio
                                                                </p>
                                                            )}
                                                        {errors.email &&
                                                            errors.email.type === 'pattern' && (
                                                                <p className="message-error-input">
                                                                    Email incorrecto. Debe ingresar
                                                                    un tipo de email adecuado
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header">
                                <h5>Ubicación</h5>
                            </div>
                            <div className="card-body">
                                <div className="row product-adding">
                                    <div className="col-xl-10 offset-xl-1">
                                        <div className="needs-validation add-product-form">
                                            <div className="form form-label-center">
                                                <div className="form-group mb-3 row">
                                                    <label
                                                        htmlFor="street1"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Dirección:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="street1"
                                                            className="form-control"
                                                            placeholder="Avenida, calle, carrera, vereda"
                                                            name="street1"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.street1 &&
                                                            errors.street1.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Dirección obligatoria
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label
                                                        htmlFor="street2"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Edificio, piso, casa
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="street2"
                                                            className="form-control"
                                                            placeholder="Número de casa, piso y/o apto"
                                                            name="street2"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.street2 &&
                                                            errors.street2.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Este campo es obligatorio
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label
                                                        htmlFor="email"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        GPS:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                height: 300,
                                                            }}
                                                            id="map"
                                                        />
                                                        {errors.location &&
                                                            errors.location.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Ubicación GPS es obligatoria
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 text-center mt-5 mb-3">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={handleSubmit(handleNewClient)}
                                    >
                                        Guardar usuario
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});

export default NewClient;
