import React, { useEffect, useState, Fragment } from 'react';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import Breadcrumb from '../common/breadcrumb';
import Loader from '../common/loader';
import api from '../../config/axios';

function GeneralSettings(props) {
    const { handleSubmit, register, errors } = useForm();

    const [info, setInfo] = useState(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        fetchInfo();
    }, []);

    const fetchInfo = async () => {
        const { data } = await api.get('/settings');
        setInfo(data.data[0]);
        setLoaded(true);
    };

    const updateInfo = async (data) => {
        data.boundary = JSON.parse(data.boundary);
        const response = await api.put('/settings/1', data);
        if (response) {
            toast('Information updated successfully');
            fetchInfo();
        } else {
            toast('An error has occurred. Try again.');
        }
    };

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    const boundaries = JSON.stringify(info.boundary);
    return (
        <Fragment>
            <Breadcrumb title="Configuración general" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row pt-3 pb-3">
                                    <div className="col-lg-12">
                                        <h4>Información de contacto</h4>
                                        <div className="form-group mb-3 row d-flex align-items-center">
                                            <label className="col-xl-3 col-sm-4 mb-0">
                                                Correo electrónico de contacto
                                            </label>
                                            <div className="col-xl-3 col-sm-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="email_contact"
                                                    defaultValue={info && info.email_contact}
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                    disabled
                                                />
                                                {errors.email_contact &&
                                                    errors.email_contact.type === 'required' && (
                                                        <p className="message-error-input">
                                                            Correo electrónico es obligatorio
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 row d-flex align-items-center">
                                            <label className="col-xl-3 col-sm-4 mb-0">
                                                Número de teléfono
                                            </label>
                                            <div className="col-xl-3 col-sm-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="number_contact"
                                                    defaultValue={info && info.number_contact}
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                    disabled
                                                />
                                                {errors.number_contact &&
                                                    errors.number_contact.type === 'required' && (
                                                        <p className="message-error-input">
                                                            Teléfono es obligatorio
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row pt-3 pb-3">
                                    <div className="col-lg-12">
                                        <h4>Valores de cambio</h4>
                                        <div className="form-group row d-flex align-items-center">
                                            <label className="col-xl-3 col-sm-4 mb-0">
                                                USD_VES
                                            </label>
                                            <div className="col-xl-2 col-sm-8">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="usdves_value"
                                                    defaultValue={info && info.usdves_value}
                                                    ref={register({
                                                        required: true,
                                                        min: 1,
                                                    })}
                                                />
                                                {errors.usdves_value &&
                                                    errors.usdves_value.type === 'required' && (
                                                        <p className="message-error-input">
                                                            Cambio USD_VES es obligatorio
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row pt-3 pb-3">
                                    <div className="col-lg-12">
                                        <h4>Información de entrega (Delivery)</h4>
                                        <div className="form-group mb-3 row d-flex align-items-center">
                                            <label className="col-xl-3 col-sm-4 mb-0">
                                                Puntos de GPS
                                            </label>
                                            <div className="col-xl-9 col-sm-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="boundary"
                                                    defaultValue={info && boundaries}
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                />
                                                {errors.boundary &&
                                                    errors.boundary.type === 'required' && (
                                                        <p className="message-error-input">
                                                            Puntos de GPS son obligatorios
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 d-flex justify-content-center mb-5">
                            <button
                                onClick={handleSubmit(updateInfo)}
                                className="btn btn-lg btn-primary"
                            >
                                update info
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default GeneralSettings;
