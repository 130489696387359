import axios from 'axios';
import { getItem, removeItem, keyLocalStorage } from './localStorage';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor
instance.interceptors.request.use(
    function (config) {
        if (getItem(keyLocalStorage)) {
            const info = getItem(keyLocalStorage);
            const token = JSON.parse(info).token;
            config.headers.Authorization = `jwt ${token}`;
        }
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    function (response) {
        // Do something before request is sent
        return response;
    },
    function (error) {
        if (
            error.response.status === 403 &&
            error.response.data.errors[0].msg.name === 'TokenExpiredError'
        ) {
            removeItem(keyLocalStorage);
            window.location.href = '/';
        }
        // Do something with request error
        return Promise.reject(error);
    },
);

export default instance;
