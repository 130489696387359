import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import useForm from 'react-hook-form';
import Loader from '../common/loader';
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import api from '../../config/axios';

const NewFranchise = withRouter(({ history }) => {
    const { handleSubmit, register, errors, setValue, getValues } = useForm();
    const [loaded, setLoaded] = useState(false);
    const [settings, setSettings] = useState(null);

    // Fetchs info when component is mounted
    useEffect(() => {
        register({ name: 'location' }, { required: true });
        Promise.all([fetchSettings()]).then((values) => {
            setSettings(values[0]);
            setLoaded(true);
        });
    }, []);

    const fetchSettings = async () => {
        const { data } = await api.get(`/settings`);
        const settings = data.data[0];

        return settings;
    };

    const handleApiLoaded = (map, maps) => {
        const triangleCoords = settings.boundary.map((item) => ({
            lat: item.latitude,
            lng: item.longitude,
        }));

        const colonCoords = [
            { lat: 8.034662127320667, lng: -72.27088088161413 },
            { lat: 8.032162021374978, lng: -72.26991456830731 },
            { lat: 8.027532154902449, lng: -72.26414785986337 },
            { lat: 8.02030945777254, lng: -72.24316950945219 },
            { lat: 8.027532154816777, lng: -72.23743397240526 },
            { lat: 8.035248569689323, lng: -72.24323185209863 },
            { lat: 8.042347541957575, lng: -72.24634899214179 },
            { lat: 8.05191552514217, lng: -72.24454105105077 },
            { lat: 8.054662420519461, lng: -72.2477828764984 },
            { lat: 8.055279697750994, lng: -72.25183515810977 },
            { lat: 8.043366079984015, lng: -72.25657321074597 },
            { lat: 8.037501735388897, lng: -72.26258929084449 },
            { lat: 8.038798071458885, lng: -72.26888591319121 },
        ];

        var SCPolygon = new maps.Polygon({
            paths: triangleCoords,
            strokeColor: '#00a4d3',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#00a4d3',
            fillOpacity: 0.15,
        });

        var ColonPolygon = new maps.Polygon({
            paths: colonCoords,
            strokeColor: '#00a4d3',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#00a4d3',
            fillOpacity: 0.15,
        });

        SCPolygon.setMap(map);
        ColonPolygon.setMap(map);
    };

    const handleFranchiseLocation = (data) => {
        setValue('location', null);
        const coordinates = {
            latitude: data.center.lat(),
            longitude: data.center.lng(),
        };
        setValue('location', coordinates);
    };

    const handleNewFranchise = (data) => {
        const dataFranchise = {
            ...data,
            phone: Number(data.phone.replace('0', '58')), //.replace() in javascript only replaces the first occurrence by default
            latitude: data.location.latitude,
            longitude: data.location.longitude,
        };

        api.post('/franchise', dataFranchise)
            .then((response) => {
                const dataSettings = {
                    franchiseId: response.data.data.franchise.id,
                    usdves_value: data.usd_ves,
                    number_contact: data.phone,
                    email_contact: data.email,
                    boundary: JSON.parse(data.boundary),
                };
                api.post('/settings', dataSettings)
                    .then((responseSettings) => {
                        toast.success('Franquicia agregada con éxito');
                        history.push(`/franquicias`);
                    })
                    .catch((error) => console.log(error));
            })
            .catch((error) => {
                toast.error('Hubo un error al agregar la franquicia. Intente de nuevo');
            });
    };

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    const locationFranchise = getValues('location');
    const greatPlaceStyle = {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    };

    return (
        <Fragment>
            <Breadcrumb title="Nueva franquicia" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Información general</h5>
                            </div>
                            <div className="card-body">
                                <div className="row product-adding">
                                    <div className="col-xl-10 offset-xl-1">
                                        <div className="needs-validation add-product-form">
                                            <div className="form form-label-center">
                                                <div className="form-group mb-4 row">
                                                    <label
                                                        htmlFor="name"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Nombre (responsable):
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.name &&
                                                            errors.name.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Nombre es obligatorio
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group mb-4 row">
                                                    <label
                                                        htmlFor="name"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Número de teléfono:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="phone"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.phone &&
                                                            errors.phone.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Número de teléfono es
                                                                    obligatorio
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header">
                                <h5>Datos de ubicación</h5>
                            </div>
                            <div className="card-body">
                                <div className="row product-adding">
                                    <div className="col-xl-10 offset-xl-1">
                                        <div className="needs-validation add-product-form">
                                            <div className="form form-label-center">
                                                <div className="form-group mb-4 row">
                                                    <label
                                                        htmlFor="name"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Dirección:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="address"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.address &&
                                                            errors.address.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Dirección es obligatoria
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group mb-4 row">
                                                    <label
                                                        htmlFor="name"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Ciudad:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="city"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.city &&
                                                            errors.city.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Ciudad es obligatoria
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group mb-4 row">
                                                    <label
                                                        htmlFor="name"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Estado:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="state"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.state &&
                                                            errors.state.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Estado es obligatorio
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group mb-4 row">
                                                    <label
                                                        htmlFor="name"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        País:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="country"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.country &&
                                                            errors.country.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    País es obligatorio
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group mb-4 row">
                                                    <label
                                                        htmlFor="email"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Ubicación en el mapa:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                height: 300,
                                                            }}
                                                        >
                                                            <GoogleMapReact
                                                                bootstrapURLKeys={{
                                                                    key: 'AIzaSyDECMBhHDb83sHE52nNUxHlpDu721Nm6j4',
                                                                }}
                                                                defaultCenter={{
                                                                    lat: 7.776,
                                                                    lng: -72.2127,
                                                                }}
                                                                onDragEnd={handleFranchiseLocation}
                                                                onGoogleApiLoaded={({
                                                                    map,
                                                                    maps,
                                                                }) => handleApiLoaded(map, maps)}
                                                                defaultZoom={17}
                                                                yesIWantToUseGoogleMapApiInternals={
                                                                    true
                                                                }
                                                            >
                                                                {locationFranchise && (
                                                                    <div
                                                                        lat={
                                                                            locationFranchise.latitude
                                                                        }
                                                                        lng={
                                                                            locationFranchise.longitude
                                                                        }
                                                                        style={greatPlaceStyle}
                                                                    >
                                                                        <img
                                                                            src="https://icon-library.com/images/google-maps-circle-icon/google-maps-circle-icon-2.jpg"
                                                                            alt="marker-map"
                                                                            style={{
                                                                                width: 40,
                                                                                height: 40,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </GoogleMapReact>
                                                        </div>
                                                        {errors.location &&
                                                            errors.location.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Ubicación en el mapa es
                                                                    obligatoria
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header">
                                <h5>Configuración de la franquicia</h5>
                            </div>
                            <div className="card-body">
                                <div className="row product-adding">
                                    <div className="col-xl-10 offset-xl-1">
                                        <div className="needs-validation add-product-form">
                                            <div className="form form-label-center">
                                                <div className="form-group mb-4 row">
                                                    <label
                                                        htmlFor="boundary"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Puntos de GPS (boundaries):
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="boundary"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.boundary &&
                                                            errors.boundary.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Puntos de GPS son obligatorios
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group mb-4 row">
                                                    <label
                                                        htmlFor="boundary"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        USD_VES:
                                                    </label>
                                                    <div className="col-xl-3 col-sm-7">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="usd_ves"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.usd_ves &&
                                                            errors.usd_ves.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    USD_VES es obligatorio
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header">
                                <h5>Datos de usuario</h5>
                            </div>
                            <div className="card-body">
                                <div className="row product-adding">
                                    <div className="col-xl-10 offset-xl-1">
                                        <div className="needs-validation add-product-form">
                                            <div className="form form-label-center">
                                                <div className="form-group mb-4 row">
                                                    <label
                                                        htmlFor="email"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Correo electrónico de usuario:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="email"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.email &&
                                                            errors.email.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Correo de usuario es obligatorio
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-group mb-4 row">
                                                    <label
                                                        htmlFor="userPassword"
                                                        className="col-xl-3 col-sm-4 mb-0"
                                                    >
                                                        Contraseña de usuario:
                                                    </label>
                                                    <div className="col-xl-6 col-sm-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="password"
                                                            ref={register({
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.password &&
                                                            errors.password.type === 'required' && (
                                                                <p className="message-error-input">
                                                                    Contraseña de usuario es
                                                                    obligatoria
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 text-center mt-5 mb-3">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={handleSubmit(handleNewFranchise)}
                                    >
                                        Guardar franquicia
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});

export default NewFranchise;
