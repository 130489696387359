import React from 'react';
export const LineOptionsUsers = {
    chartArea: { left: '10%', top: '10%', width: '85%', height: '80%' },
    colors: ['orange'],
    legend: 'none',
};

export const LineOptionsRevenue = {
    chartArea: { left: '10%', top: '10%', width: '85%', height: '80%' },
    colors: ['green'],
    legend: 'none',
};

export const columnsFranchisesTable = [
    {
        Header: <b>Usuario</b>,
        accessor: 'name',
        Cell: null,
        style: {
            textAlign: 'left',
        },
    },
    {
        Header: <b>Ver</b>,
        accessor: 'show',
        Cell: (row) => (
            <div>
                <span onClick={() => (window.location.href = `/dashboard/${row.original.id}`)}>
                    <i
                        className="fa fa-eye"
                        style={{
                            width: 35,
                            fontSize: 20,
                            padding: 11,
                            color: '#000000',
                            cursor: 'pointer',
                        }}
                    ></i>
                </span>
            </div>
        ),
        style: {
            textAlign: 'center',
        },
        width: 150,
    },
];

export const columnsUsersTable = [
    {
        Header: <b>Nombre</b>,
        accessor: 'name',
        Cell: null,
        style: {
            textAlign: 'left',
        },
    },
    {
        Header: <b>Ver</b>,
        accessor: 'show',
        Cell: (row) => (
            <div>
                <span
                    onClick={() =>
                        (window.location.href = `/dashboard/${row.original.franchise.id}/users/${row.original.id}`)
                    }
                >
                    <i
                        className="fa fa-eye"
                        style={{
                            width: 35,
                            fontSize: 20,
                            padding: 11,
                            color: '#000000',
                            cursor: 'pointer',
                        }}
                    ></i>
                </span>
            </div>
        ),
        style: {
            textAlign: 'center',
        },
        width: 150,
    },
];

export const columnsDeliveryUsersTable = [
    {
        Header: <b>Nombre</b>,
        accessor: 'userName',
        Cell: (row) => (
            <div>
                <a href={`/dashboard/${row.original.franchiseId}/users/${row.original.userId}`}>
                    {row.original.userName}
                </a>
            </div>
        ),
        style: {
            textAlign: 'left',
        },
    },
    {
        Header: <b>Conteo diario</b>,
        accessor: 'count',
        Cell: null,
        style: {
            textAlign: 'center',
        },
        width: 150,
    },
    {
        Header: <b>Cambios</b>,
        accessor: 'changes',
        Cell: null,
        style: {
            textAlign: 'center',
        },
        width: 150,
    },
];
