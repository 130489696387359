import React, { useEffect, useReducer, Fragment } from 'react';
import { Link } from 'react-router-dom';
import useForm from 'react-hook-form';

import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import Loader from '../common/loader';
import api from '../../config/axios';

function Franchises(props) {
    const { setValue, clearError } = useForm();
    const [stateFranchises, setStateFranchises] = useReducer((s, a) => ({ ...s, ...a }), {
        resolved: false,
        data: null,
        franchises: [],
        pagination: {
            page: 1,
        },
        loading: true,
        error: null,
    });

    useEffect(() => {
        fetchFranchises(stateFranchises.pagination.page);
    }, []);

    const fetchFranchises = async (page) => {
        const { data } = await api.get(`/franchise?page=${page}`);
        const franchises = parseToDatatable(data.data.rows);

        const dataPagination = {
            ...data.meta.pagination,
            total: data.data.count,
        };
        setStateFranchises({
            resolved: true,
            loading: false,
            pagination: dataPagination,
            franchises,
        });
    };

    const parseToDatatable = (array) => {
        const parsed = array.map((item) => {
            return {
                id: item.id,
                nombre: item.name,
                address: item.address,
                ciudad: item.city,
                Teléfono: `+${item.phone}`,
            };
        });
        return parsed;
    };

    const handlePage = (page) => {
        fetchFranchises(page);
    };

    const totalPages = () =>
        Math.ceil(stateFranchises.pagination.total / stateFranchises.pagination.length);

    if (!stateFranchises.resolved)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );
    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            <Breadcrumb title="Franquicias" />
            <div className="container-fluid rents-wrapper pt-2">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="btn-popup pull-right">
                                    <Link
                                        className="btn btn-primary"
                                        to={`${process.env.PUBLIC_URL}/franquicias/nueva-franquicia`}
                                    >
                                        Nueva Franquicia
                                    </Link>
                                </div>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="order-list">
                                    <Datatable
                                        class="-striped -highlight"
                                        multiSelectOption={false}
                                        data={stateFranchises.franchises}
                                        page={stateFranchises.pagination.page - 1}
                                        pages={totalPages()}
                                        defaultPageSize={stateFranchises.pagination.length}
                                        showPageSizeOptions={false}
                                        onPageChange={(index) => handlePage(index + 1)}
                                        options={false}
                                        manual
                                        getTrProps={(state, rowInfo, column, instance) => ({
                                            onClick: (e) =>
                                                props.history.push(
                                                    `/franquicias/${rowInfo.original.id}`,
                                                ),
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    );
}

export default Franchises;
