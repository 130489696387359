import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import 'react-toastify/dist/ReactToastify.css';

export class Datatable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedValues: [],
        };
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
        const { myClass } = this.props;
        const { data, options } = this.props;

        const columns = [];
        for (var key in data[0]) {
            if (key === 'Id Cliente') {
                columns.push({
                    Header: <b>#</b>,
                    accessor: key,
                    Cell: null,
                    style: {
                        textAlign: 'center',
                    },
                    width: 80,
                });
            } else if (key !== 'id' && key !== 'src') {
                columns.push({
                    Header: <b>{this.Capitalize(key.toString())}</b>,
                    accessor: key,
                    Cell: null,
                    style: {
                        textAlign: 'center',
                    },
                });
            }
        }

        if (data.length > 0 && options) {
            columns.push({
                Header: <b>Action</b>,
                id: 'delete',
                accessor: (str) => 'delete',
                Cell: (row) => (
                    <div>
                        <span onClick={() => this.props.onDelete(row.original)}>
                            <i
                                className="fa fa-trash"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: '#000000',
                                    cursor: 'pointer',
                                }}
                            ></i>
                        </span>

                        <span onClick={() => this.props.onEdit(row.original)}>
                            <i
                                className="fa fa-pencil"
                                style={{
                                    width: 35,
                                    fontSize: 20,
                                    padding: 11,
                                    color: '#000000',
                                    cursor: 'pointer',
                                }}
                            ></i>
                        </span>
                    </div>
                ),
                style: {
                    textAlign: 'center',
                },
                sortable: false,
            });
        } else if (data.length === 0) {
            columns.push(
                {
                    Header: <b>Image</b>,
                    style: {
                        textAlign: 'center',
                    },
                    sortable: false,
                },
                {
                    Header: <b>Name</b>,
                    style: {
                        textAlign: 'center',
                    },
                    sortable: false,
                },
                {
                    Header: <b>Status</b>,
                    style: {
                        textAlign: 'center',
                    },
                    sortable: false,
                },
                {
                    Header: <b>Action</b>,
                    style: {
                        textAlign: 'center',
                    },
                    sortable: false,
                },
            );
        }
        return (
            <Fragment>
                <ReactTable columns={columns} className={myClass} {...this.props} />
            </Fragment>
        );
    }
}

export default Datatable;
