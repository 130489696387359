import React, { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import useForm from 'react-hook-form';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import Breadcrumb from '../common/breadcrumb';
import api from '../../config/axios';

function CreateCoupon(props) {
    const { handleSubmit, watch, register, errors, setValue } = useForm();

    const [info, setInfo] = useState(null);
    const [focusStartDate, setFocusStartDate] = useState('');
    const [dates, setDates] = useState({
        startDate: null,
        endDate: null,
    });
    const [franchises, setFranchises] = useState([]);

    useEffect(() => {
        fetchFranchises();
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: false });
    }, []);

    const handleCoupon = (data) => {
        const dataCoupon = {
            ...data,
            startDate: data.startDate ? moment(data.startDate).toDate() : null,
            endDate: data.endDate ? moment(data.endDate).toDate() : null,
            useLimit: data.useLimit ? Number(data.useLimit) : null,
        };

        api.post('/coupons', dataCoupon)
            .then(() => {
                toast('Cupón creado con éxito');
                props.history.push('/cupones');
            })
            .catch((error) => toast('Error creando cupón. Intente de nuevo!'));
    };

    const fetchFranchises = async () => {
        const { data } = await api.get(`/franchise`);
        const franchises = data.data.rows;

        setFranchises(franchises);
    };

    const type = watch('type');
    return (
        <Fragment>
            <Breadcrumb title="Nuevo cupón" parent={{ title: 'Cupones', href: '/cupones' }} />
            <div className="container-fluid">
                <div className="card">
                    <div className="card-body">
                        <div className="tab-pane fade active show">
                            <form
                                className="needs-validation"
                                onSubmit={handleSubmit(handleCoupon)}
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4>Información del cupón</h4>
                                        <div className="form-group mb-3 row d-flex align-items-center">
                                            <label className="col-xl-2 col-sm-4 mb-0">
                                                <span>*</span> Código
                                            </label>
                                            <div className="col-xl-4 col-md-7 col-sm-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="code"
                                                    defaultValue={info && info.code}
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                />
                                                {errors.code && errors.code.type === 'required' && (
                                                    <p className="message-error-input">
                                                        Código es obligatorio
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 row d-flex align-items-center">
                                            <label className="col-xl-2 col-sm-4 mb-0">
                                                Descripción
                                            </label>
                                            <div className="col-xl-6 col-md-7 col-sm-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="description"
                                                    defaultValue={info && info.description}
                                                    ref={register({
                                                        required: false,
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 row d-flex align-items-center">
                                            <label className="col-xl-2 col-sm-4 mb-0">
                                                <span>*</span> Franquicia
                                            </label>
                                            <div className="col-xl-4 col-md-7 col-sm-8">
                                                <select
                                                    className="custom-select form-control"
                                                    required=""
                                                    name="franchiseId"
                                                    defaultValue={info && info.type}
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                >
                                                    <option value="">
                                                        Selecciona la franquicia para el cupón
                                                    </option>
                                                    {franchises.map((item, index) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                                {errors.type && errors.type.type === 'required' && (
                                                    <p className="message-error-input">
                                                        Franquicia es obligatorio
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <h4>Tipo de cupón</h4>
                                        <div className="form-group mb-3 row d-flex align-items-center">
                                            <label className="col-xl-2 col-sm-4 mb-0">
                                                <span>*</span> Tipo de descuento
                                            </label>
                                            <div className="col-xl-4 col-md-7 col-sm-8">
                                                <select
                                                    className="custom-select form-control"
                                                    required=""
                                                    name="type"
                                                    defaultValue={info && info.type}
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                >
                                                    <option value="">
                                                        Selecciona el tipo de cupón
                                                    </option>
                                                    <option value="PERCENTAGE">Porcentaje</option>
                                                    <option value="AMOUNT_FIXED">Monto fijo</option>
                                                </select>
                                                {errors.type && errors.type.type === 'required' && (
                                                    <p className="message-error-input">
                                                        Type is required
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 row d-flex align-items-center">
                                            <label className="col-xl-2 col-sm-4 mb-0">
                                                <span>*</span> Valor del cupón
                                            </label>
                                            <div className="col-xl-5 col-md-6 col-sm-8">
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <input
                                                        type="number"
                                                        className="form-control ml-0 mr-0"
                                                        name="amount_usd"
                                                        placeholder="Monto en USD"
                                                        defaultValue={info && info.amount_usd}
                                                        ref={register({
                                                            required: true,
                                                        })}
                                                    />
                                                    &nbsp;
                                                    {type === 'PERCENTAGE' && <span>%</span>}
                                                    {type === 'AMOUNT_FIXED' && <span>$</span>}
                                                    <input
                                                        type="number"
                                                        className="form-control ml-0 mr-0"
                                                        name="amount_cop"
                                                        placeholder="Monto en COP"
                                                        defaultValue={info && info.amount_cop}
                                                        ref={register({
                                                            required: true,
                                                        })}
                                                    />
                                                    &nbsp;
                                                    {type === 'PERCENTAGE' && <span>%</span>}
                                                    {type === 'AMOUNT_FIXED' && <span>$</span>}
                                                </div>
                                                {errors.amount_usd &&
                                                    errors.amount_usd.type === 'required' && (
                                                        <p className="message-error-input">
                                                            Monto en USD es obligatorio
                                                        </p>
                                                    )}
                                                {errors.amount_cop &&
                                                    errors.amount_cop.type === 'required' && (
                                                        <p className="message-error-input">
                                                            Monto en COP es obligatorio
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 row d-flex align-items-center">
                                            <label className="col-xl-2 col-sm-4 mb-0">
                                                <span>*</span> Límite de uso
                                            </label>
                                            <div className="col-xl-2 col-sm-3 col-xs-4">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Cuantos cupones?"
                                                        name="useLimit"
                                                        defaultValue={info && info.useLimit}
                                                        ref={register({
                                                            required: true,
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <h4>Validez del cupón</h4>
                                        <div className="form-group mb-3 row d-flex align-items-center">
                                            <label className="col-xl-2 col-sm-4 mb-0">
                                                <span>*</span> Periodo de días
                                            </label>
                                            <div className="col-xl-5 col-sm-8">
                                                <DateRangePicker
                                                    //minDate={minDate}
                                                    startDate={dates.startDate}
                                                    startDateId="your_unique_start_date_id"
                                                    endDate={dates.endDate}
                                                    endDateId="your_unique_end_date_id"
                                                    onDatesChange={(dates) => {
                                                        setDates({
                                                            startDate: dates.startDate,
                                                            endDate: dates.endDate,
                                                        });
                                                        setValue('startDate', dates.startDate);
                                                        if (dates.endDate) {
                                                            setValue('endDate', dates.endDate);
                                                        }
                                                    }}
                                                    startDatePlaceholderText="Dia de inicio*"
                                                    endDatePlaceholderText="Dia de fin"
                                                    focusedInput={focusStartDate}
                                                    onFocusChange={(focusedInput) =>
                                                        setFocusStartDate(focusedInput)
                                                    }
                                                />
                                                {errors.startDate &&
                                                    errors.startDate.type === 'required' && (
                                                        <p className="message-error-input">
                                                            Start Date is required
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleSubmit(handleCoupon)}
                    >
                        guardar cupón
                    </button>
                </div>
            </div>
        </Fragment>
    );
}

export default CreateCoupon;
