import React, { useState, useEffect, Fragment } from 'react';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import Breadcrumb from '../common/breadcrumb';
import Loader from '../common/loader';
import api from '../../config/axios';
import utils from '../../utils/miscUtils';

const columnsDataTable = [
    {
        Header: <b>Código</b>,
        accessor: 'code',
        Cell: null,
        style: {
            textAlign: 'center',
        },
        width: 200,
    },
    {
        Header: <b>Descripcion</b>,
        accessor: 'description',
        Cell: null,
        style: {
            textAlign: 'center',
        },
        sortable: false,
    },
    {
        Header: <b>Valor</b>,
        accessor: 'value',
        Cell: null,
        style: {
            textAlign: 'center',
        },
        width: 100,
    },
    {
        Header: <b>Uso</b>,
        accessor: 'timesUsed',
        Cell: null,
        style: {
            textAlign: 'center',
        },
        width: 120,
    },
    {
        Header: <b>Start date - End date</b>,
        accessor: 'period',
        Cell: null,
        style: {
            textAlign: 'center',
        },
        width: 220,
    },
    {
        Header: <b>Estado</b>,
        accessor: 'status',
        Cell: null,
        style: {
            textAlign: 'center',
        },
        width: 100,
    },
    /* {
        Header: <b>Opción</b>,
        id: 'delete',
        accessor: (str) => 'delete',
        Cell: (row) => (
            <div>
                <span onClick={() => row.original.actionDelete(row.original)}>
                    <i
                        className="fa fa-trash"
                        style={{
                            width: 35,
                            fontSize: 20,
                            padding: 11,
                            color: '#000000',
                            cursor: 'pointer',
                        }}
                    ></i>
                </span>
            </div>
        ),
        style: {
            textAlign: 'center',
        },
        sortable: false,
        width: 80,
    }, */
];

function Coupons(props) {
    const [coupons, setCoupons] = useState({
        list: [],
        loaded: false,
        pagination: {
            page: 1,
        },
    });
    const [modalDelete, setModalDelete] = useState({
        visible: false,
        data: {},
    });

    useEffect(() => {
        fetchCoupons(coupons.pagination.page);
    }, []);

    const fetchCoupons = async () => {
        const { data } = await api.get(`/coupons`);
        const coupons = parseToDatatable(data.data);

        setCoupons({
            loaded: true,
            list: coupons,
        });
    };

    const deleteCoupon = (id) => {
        api.delete(`/coupons/${modalDelete.data.id}`)
            .then(() => {
                toast('Coupon cancelled successfully');
                setModalDelete({
                    visible: false,
                    data: {},
                });
                fetchCoupons();
            })
            .catch((error) => toast('Error creating coupon. Try again.'));
    };

    const handleModalDelete = (data) => {
        setModalDelete({
            visible: true,
            data,
        });
    };

    const amountCoupon = (coupon) => {
        if (coupon.type === 'PERCENTAGE') {
            return `${Number(coupon.amount_usd)} %`;
        } else if (coupon.type === 'AMOUNT_FIXED') {
            return `${coupon.amount_usd} USD  ${coupon.amount_cop} COP`;
        }
    };

    const parseToDatatable = (array) => {
        const parsed = array.map((item) => {
            return {
                id: item.id,
                code: item.code,
                description: item.description,
                value: amountCoupon(item),
                timesUsed: `${item.timesUsed} (${item.useLimit})`,
                period: `${moment(item.startDate).format(utils.formatDate)} - ${
                    item.endDate ? moment(item.endDate).format(utils.formatDate) : 'No end date'
                }`,
                status: renderBadge(item.active),
                actionDelete: handleModalDelete,
            };
        });
        return parsed;
    };

    const addCoupon = () => {
        props.history.push('/cupones/crear-coupon');
    };

    const handlePage = (page) => {
        fetchCoupons();
    };

    const renderBadge = (status) => {
        switch (status) {
            case true:
                return <span className="badge badge-success">Active</span>;
            case false:
                return <span className="badge badge-danger">Inactive</span>;
            default:
                return null;
        }
    };

    if (!coupons.loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );
    return (
        <Fragment>
            <Breadcrumb title="Cupones" />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="btn-popup pull-right">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={addCoupon}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"
                                    >
                                        Nuevo Cupón
                                    </button>
                                </div>
                                <div className="clearfix"></div>
                                <div className="table-boxes">
                                    <ReactTable
                                        columns={columnsDataTable}
                                        className="-striped -highlight"
                                        multiSelectOption={false}
                                        data={coupons.list}
                                        page={1}
                                        defaultPageSize={10}
                                        showPageSizeOptions={false}
                                        onPageChange={(index) => handlePage(index + 1)}
                                        manual
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDelete
                visible={modalDelete.visible}
                handleSubmit={deleteCoupon}
                handleClose={() => setModalDelete({ visible: false, data: {} })}
            />
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    );
}

const ModalDelete = (props) => {
    const { visible, handleSubmit, handleClose } = props;
    return (
        <Modal open={visible} onClose={handleClose}>
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Desactivar cupón
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <p>Estas seguro de desactivar este cupón?</p>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                    Si
                </button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                    No
                </button>
            </div>
        </Modal>
    );
};

export default Coupons;
