import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';

import Breadcrumb from '../common/breadcrumb';
import api from '../../config/axios';

const ClientsNotification = withRouter((props) => {
    const {
        handleSubmit: handleSubmitPush,
        register: registerPush,
        errors: errorsPush,
        watch,
    } = useForm();

    const sendPushNotification = (data) => {
        const notificationPush = {
            ...data,
            data: {
                view: data.typeNotification === 'home' ? null : undefined,
                link: data.link || null,
            },
        };
        api.post(`/client/notifications`, notificationPush)
            .then(async () => {
                const dataNotificationTable = {
                    title: data.title,
                    description: data.body,
                    clientId: null,
                    orderId: null,
                    type: 'general',
                };
                await api.post(`/notifications`, dataNotificationTable);
                toast.success('Notificatión enviada con éxito');
            })
            .catch((error) => {
                console.log(error);
                toast.error('Hubo un error al enviar la notificación. Intente de nuevo');
            });
    };

    const typeNotificationSelected = watch('typeNotification');

    return (
        <Fragment>
            <Breadcrumb title="Notificacion a los clientes" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form
                                    onSubmit={handleSubmitPush(sendPushNotification)}
                                    className="needs-validation user-add"
                                    noValidate=""
                                >
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Título
                                        </label>
                                        <input
                                            className="form-control col-xl-4 col-md-7"
                                            type="text"
                                            name="title"
                                            ref={registerPush({
                                                required: true,
                                            })}
                                        />
                                        <p className="message-error-input">
                                            {errorsPush.title &&
                                                errorsPush.title.type === 'required' &&
                                                'Escribe un titulo de la notificación'}
                                        </p>
                                    </div>
                                    <div className="form-group row mb-5">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Mensaje
                                        </label>
                                        <input
                                            className="form-control col-xl-5 col-md-7"
                                            type="text"
                                            name="body"
                                            ref={registerPush({
                                                required: true,
                                            })}
                                        />
                                        <p className="message-error-input">
                                            {errorsPush.body &&
                                                errorsPush.body.type === 'required' &&
                                                'Escribe un mensaje descriptivo'}
                                        </p>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4 mb-2">
                                            <span>*</span> Enviar a
                                        </label>
                                        <div className="col-xl-3 col-md-6">
                                            <input
                                                type="radio"
                                                name="clients"
                                                value=""
                                                ref={registerPush({
                                                    required: true,
                                                })}
                                            />
                                            &nbsp;&nbsp;
                                            <label for="all">Todos los clientes</label>
                                        </div>
                                        <div className="col-xl-3 col-md-6">
                                            <input
                                                type="radio"
                                                name="clients"
                                                value="40b94417-6af0-4ebd-b6f3-9d962e47b7dc"
                                                ref={registerPush({
                                                    required: true,
                                                })}
                                            />
                                            &nbsp;&nbsp; Colón
                                        </div>
                                        <div className="col-xl-3 col-md-6">
                                            <input
                                                type="radio"
                                                name="clients"
                                                value="5e95f51f-5b66-4c58-b2af-7a54e8b1df1e"
                                                ref={registerPush({
                                                    required: true,
                                                })}
                                            />
                                            &nbsp;&nbsp; San Cristóbal
                                        </div>
                                        <p className="message-error-input">
                                            {errorsPush.clients &&
                                                errorsPush.clients.type === 'required' &&
                                                'Escoge a quien le quieres enviar la notificación'}
                                        </p>
                                    </div>
                                    <div className="form-group row mt-5">
                                        <label className="col-xl-3 col-md-4">
                                            <span></span> Tipo de notificación
                                        </label>
                                        <div className="col-xl-2 col-md-6">
                                            <input
                                                placeholder="RecargarBotellon o DetailOrder"
                                                type="radio"
                                                name="typeNotification"
                                                value="home"
                                                ref={registerPush({
                                                    required: true,
                                                })}
                                            />
                                            &nbsp;&nbsp; Home
                                        </div>
                                        <div className="col-xl-2 col-md-6">
                                            <input
                                                placeholder="RecargarBotellon o DetailOrder"
                                                type="radio"
                                                name="typeNotification"
                                                value="link"
                                                ref={registerPush({
                                                    required: true,
                                                })}
                                            />
                                            &nbsp;&nbsp; Link externo
                                        </div>
                                        <p className="message-error-input">
                                            {errorsPush.typeNotification &&
                                                errorsPush.typeNotification.type === 'required' &&
                                                'Escoge la acción de la notificación'}
                                        </p>
                                    </div>
                                    {typeNotificationSelected === 'link' ? (
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4">
                                                <span></span> Link
                                            </label>
                                            <input
                                                className="form-control col-xl-5 col-md-7"
                                                placeholder="https://www.instagram.com/p/CNqYSqwhSMs/"
                                                type="text"
                                                name="link"
                                                ref={registerPush({
                                                    required: false,
                                                })}
                                            />
                                        </div>
                                    ) : null}
                                    <div className="col-sm-12 text-center mt-5 mb-3">
                                        <button
                                            type="submit"
                                            className="btn btn-success"
                                            onClick={handleSubmitPush(sendPushNotification)}
                                        >
                                            Enviar notificación
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});

export default ClientsNotification;
