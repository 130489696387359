import React, { useState, useEffect, Fragment } from 'react';
import Modal from 'react-responsive-modal';
import useForm from 'react-hook-form';
import Select from 'react-select';
import moment from 'moment';
import { toast } from 'react-toastify';

import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import Loader from '../common/loader';
import api from '../../config/axios';
import { useStateValue } from '../../store';

const ModalForm = (props) => {
    const {
        type,
        visible,
        handleSubmit,
        handleClose,
        register,
        clearError,
        setValue,
        errors,
        data,
    } = props;

    const handleSelectOption = (field, value) => {
        setValue(field, value);
        clearError(field);
    };

    const dataAccount = data && data.cuentas ? JSON.parse(data.cuentas) : null;
    return (
        <Modal
            open={visible}
            onClose={handleClose}
            styles={{ modal: { width: 400 }, closeButton: { cursor: 'pointer' } }}
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {type} Forma de pago
                </h5>
            </div>
            <div className="modal-body">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name" className="col-form-label">
                            Nombre:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Ingrese el nombre de la forma de pago"
                            name="name"
                            ref={register({
                                required: true,
                            })}
                            defaultValue={data && data.nombre}
                        />
                        {errors.nombre && errors.nombre.type === 'required' && (
                            <p className="message-error-input">Nombre es obligatorio</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="description" className="col-form-label">
                            Moneda:
                        </label>
                        <Select
                            name="currency"
                            onChange={(value) => handleSelectOption('currency', value)}
                            placeholder="Seleccione la moneda"
                            defaultValue={
                                data && {
                                    value: data.moneda,
                                    label: data.moneda,
                                }
                            }
                            options={[
                                {
                                    value: 'VES',
                                    label: 'VES',
                                },
                                {
                                    value: 'COP',
                                    label: 'COP',
                                },
                                {
                                    value: 'USD',
                                    label: 'USD',
                                },
                            ]}
                            ref={() =>
                                register(
                                    {
                                        name: 'currency',
                                        type: 'custom',
                                    },
                                    {
                                        required: true,
                                    },
                                )
                            }
                        />
                        {errors.currency && errors.currency.type === 'required' && (
                            <p className="message-error-input">Tipo de moneda es requerido</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="idaccount" className="col-form-label">
                            Cuentas bancarias (si aplica):
                        </label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            name="accountnumber"
                            ref={register({
                                required: false,
                            })}
                            placeholder="Número de cuenta"
                            defaultValue={dataAccount && dataAccount.number}
                        />
                        <input
                            type="text"
                            className="form-control mb-2"
                            name="bankname"
                            ref={register({
                                required: false,
                            })}
                            placeholder="Nombre de banco"
                            defaultValue={dataAccount && dataAccount.bank}
                        />
                        <input
                            type="text"
                            className="form-control mb-2"
                            name="accountid"
                            ref={register({
                                required: false,
                            })}
                            placeholder="Cédula"
                            defaultValue={dataAccount && dataAccount.id}
                        />

                        <input
                            type="text"
                            className="form-control mb-2"
                            name="accountphone"
                            ref={register({
                                required: false,
                            })}
                            placeholder="Número de teléfono"
                            defaultValue={dataAccount && dataAccount.phone}
                        />
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                    {type}
                </button>
            </div>
        </Modal>
    );
};

const ModalDelete = (props) => {
    const { visible, handleSubmit, handleClose } = props;
    return (
        <Modal open={visible} onClose={handleClose}>
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Eliminar forma de pago
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <p>Está seguro de eliminar esta forma de pago?</p>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                    Sí
                </button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                    No
                </button>
            </div>
        </Modal>
    );
};

function Payments() {
    const { handleSubmit, register, errors, clearError, setValue } = useForm();
    const [{ auth }] = useStateValue();
    const [loaded, setLoaded] = useState(false);
    const [open, setOpen] = useState(false);
    const [visibleModalDelete, setVisibleModalDelete] = useState(false);
    const [visibleModalUpdate, setVisibleModalUpdate] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [pagination, setPagination] = useState({ page: 1 });
    const [pageTable, setPageTable] = useState(0);
    const [infoPaymentMethod, setPaymentMethod] = useState(null);

    useEffect(() => {
        fetchPayments(pagination.page);
    }, []);

    const fetchPayments = async (page) => {
        const { data } = await api.get(`/payment_method`);
        const paymentMethods = parseToDatatable(data.data.rows);

        const dataPagination = {
            ...data.meta.pagination,
            total: data.data.count,
        };
        setPaymentMethods(paymentMethods);
        setPagination(dataPagination);
        setPageTable(data.meta.pagination.page - 1);
        setLoaded(true);
    };

    const parseToDatatable = (array) => {
        const parsed = array.map((item) => {
            return {
                id: item.id,
                nombre: item.name,
                moneda: item.currency,
                //accounts: item.accounts,
                Franquicia: item.franchise.address,
                'Fecha creación': moment(item.createdAt).format('DD/MM/YYYY'),
                cuentas: item.accounts && JSON.stringify(item.accounts[0]),
            };
        });
        return parsed;
    };

    const addPaymentMethod = async (data) => {
        const newPaymentMethod = {
            ...data,
            franchiseId: auth.user.franchiseId,
            currency: data.currency.value,
            accounts:
                data.accountid && data.bankname
                    ? [
                          {
                              id: data.accountid,
                              bank: data.bankname,
                              number: data.accountnumber || undefined,
                              phone: data.accountphone || undefined,
                              name: undefined,
                          },
                      ]
                    : null,
        };
        setLoaded(false);
        try {
            await api.post('/payment_method', newPaymentMethod);
            fetchPayments(1);
            onCloseModal();
            setLoaded(true);
            toast.success('Creado con éxito');
        } catch (error) {
            setLoaded(true);
        }
    };

    const updatePaymentMethod = async (data) => {
        setLoaded(false);
        const newPaymentMethod = {
            ...data,
            franchiseId: auth.user.franchiseId,
            currency: data.currency.value,
            accounts:
                data.accountid && data.bankname
                    ? [
                          {
                              id: data.accountid,
                              bank: data.bankname,
                              number: data.accountnumber || undefined,
                              phone: data.accountphone || undefined,
                              name: undefined,
                          },
                      ]
                    : null,
        };

        try {
            await api.put(`/payment_method/${infoPaymentMethod.id}`, newPaymentMethod);
            fetchPayments(1);
            onCloseModalUpdate();
            setLoaded(true);
            toast.success('Actualizado con éxito');
        } catch (error) {
            setLoaded(true);
        }
    };

    const deletePaymentMethod = async () => {
        setLoaded(false);
        try {
            await api.delete(`/payment_method/${infoPaymentMethod.id}`);
            fetchPayments(1);
            onCloseModalDelete();
            setLoaded(true);
            toast.info('Eliminado con éxito');
        } catch (error) {
            setLoaded(true);
        }
    };

    const handlePage = (page) => {
        fetchPayments(page);
    };

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const onOpenModalUpdate = (info) => {
        setPaymentMethod(info);
        setVisibleModalUpdate(true);
    };

    const onCloseModalUpdate = () => {
        setVisibleModalUpdate(false);
    };

    const onOpenModalDelete = (info) => {
        setPaymentMethod(info);
        setVisibleModalDelete(true);
    };

    const onCloseModalDelete = () => {
        setVisibleModalDelete(false);
    };

    const totalPages = () => Math.ceil(pagination.total / pagination.length);

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );
    return (
        <Fragment>
            <Breadcrumb title="Formas de pago" />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="btn-popup pull-right">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"
                                    >
                                        Agregar forma de pago
                                    </button>
                                    <ModalForm
                                        type={'Agregar'}
                                        visible={open}
                                        handleSubmit={handleSubmit(addPaymentMethod)}
                                        handleClose={onCloseModal}
                                        register={register}
                                        errors={errors}
                                        clearError={clearError}
                                        setValue={setValue}
                                    />
                                    <ModalForm
                                        type={'Modificar'}
                                        data={infoPaymentMethod}
                                        visible={visibleModalUpdate}
                                        handleSubmit={handleSubmit(updatePaymentMethod)}
                                        handleClose={onCloseModalUpdate}
                                        register={register}
                                        errors={errors}
                                        clearError={clearError}
                                        setValue={setValue}
                                    />
                                    <ModalDelete
                                        visible={visibleModalDelete}
                                        handleSubmit={deletePaymentMethod}
                                        handleClose={onCloseModalDelete}
                                    />
                                </div>
                                <div className="clearfix"></div>
                                <div className="table-boxes">
                                    <Datatable
                                        class="-striped -highlight"
                                        multiSelectOption={false}
                                        data={paymentMethods}
                                        page={pageTable}
                                        pages={totalPages()}
                                        defaultPageSize={pagination.length}
                                        showPageSizeOptions={false}
                                        onPageChange={(index) => handlePage(index + 1)}
                                        onEdit={onOpenModalUpdate}
                                        onDelete={onOpenModalDelete}
                                        options={true}
                                        manual
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    );
}

export default Payments;
