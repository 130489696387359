import React from 'react';

function Loader() {
    return (
        <div style={styleLoader}>
            <div className="loadingio-spinner-ripple-bmnromtkaea">
                <div className="ldio-ewier90qsg9">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}

const styleLoader = {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
};

export default Loader;
