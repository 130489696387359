import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import useForm from 'react-hook-form';
import Datatable from '../common/datatable';
import Loader from '../common/loader';
import api from '../../config/axios';
import { useStateValue } from '../../store';

function Clients(props) {
    const { handleSubmit, register } = useForm();
    const [{ auth }] = useStateValue();
    const [loaded, setLoaded] = useState(false);
    const [clients, setClients] = useState([]);
    const [pagination, setPagination] = useState({ page: 1 });
    const [pageTable, setPageTable] = useState(0);
    const [filter, setFilter] = useState(true);

    const franchiseId = auth.user.franchiseId || null;
    const baseUrlClients = franchiseId ? '/client/franchise' : '/client';

    useEffect(() => {
        fetchClients(pagination.page);
    }, []);

    const fetchClients = async (page) => {
        const { data } = await api.get(`${baseUrlClients}?franchiseId=${franchiseId}&page=${page}`);
        const clients = parseToDatatable(data.data.rows);

        const dataPagination = {
            ...data.meta.pagination,
            total: data.data.count,
        };
        setClients(clients);
        setPagination(dataPagination);
        setPageTable(data.meta.pagination.page - 1);
        setLoaded(true);
    };

    const handleFilter = (data) => {
        const dataRequest = {
            name: data.name !== '' ? `&name=${data.name}` : '',
        };

        filterClients(dataRequest);
    };

    const filterClients = async (dataRequest) => {
        const { data } = await api.get(`${baseUrlClients}?page=${1}${dataRequest.name}`);
        const clients = parseToDatatable(data.data.rows);

        const dataPagination = {
            ...data.meta.pagination,
            total: data.data.count,
        };
        setClients(clients);
        setPagination(dataPagination);
        setPageTable(data.meta.pagination.page - 1);
        setLoaded(true);
    };

    const parseToDatatable = (array) => {
        const parsed = array.map((item) => {
            return {
                id: item.id,
                'Id Cliente': item.id,
                nombre: item.name,
                email: item.email,
                Teléfono: `+ ${item.phone_number}`,
                'Token push': item.expoToken ? 'Si' : 'No',
            };
        });
        return parsed;
    };

    const handlePage = (page) => {
        fetchClients(page);
    };

    const totalPages = () => Math.ceil(pagination.total / pagination.length);

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );
    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid rents-wrapper pt-2">
                <div className="row">
                    <div className="col-sm-12">
                        <div
                            className={`card ${!filter ? 'filter-hidden' : ''}`}
                            style={{ marginBottom: 10 }}
                        >
                            <form type="submit" onSubmit={handleSubmit(handleFilter)}>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>
                                            <strong>Buscar cliente</strong>
                                        </span>
                                    </div>
                                    <div className="row pt-3 pb-3 pl-3">
                                        <div className="col-xl-6 col-8">
                                            <div className="form-group mb-3 row d-flex align-items-center">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Escriba nombre aquí..."
                                                    name="name"
                                                    ref={register({
                                                        required: false,
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-4 text-right">
                                            <button
                                                className="btn btn-primary"
                                                type="submit"
                                                onClick={handleSubmit(handleFilter)}
                                            >
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <Link
                                            className="btn btn-secondary"
                                            to={`${process.env.PUBLIC_URL}/notificacion`}
                                        >
                                            <i className="fa fa-bell"></i>
                                        </Link>
                                        <Link
                                            className="btn btn-success"
                                            to={`${process.env.PUBLIC_URL}/nuevo-cliente`}
                                        >
                                            Nuevo cliente
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div id="basicScenario" className="order-list">
                                    <Datatable
                                        class="-striped -highlight"
                                        multiSelectOption={false}
                                        data={clients}
                                        page={pageTable}
                                        pages={totalPages()}
                                        defaultPageSize={pagination.length}
                                        showPageSizeOptions={false}
                                        onPageChange={(index) => handlePage(index + 1)}
                                        options={false}
                                        manual
                                        nextText="Siguiente"
                                        previousText="Anterior"
                                        getTrProps={(state, rowInfo, column, instance) => ({
                                            onClick: (e) =>
                                                props.history.push(
                                                    `/clientes/${rowInfo.original.id}`,
                                                ),
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    );
}

export default Clients;
