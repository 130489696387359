import React, { Component, Fragment } from 'react';
import { useStateValue } from '../../store';
import api from '../../config/axios';
import { setItem, keyLocalStorage } from '../../config/localStorage';
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';

function Profile() {
    const [{ auth }, dispatch] = useStateValue();

    const handleAvailabilityToWork = async (value) => {
        try {
            const response = await api.post(`/user/${auth.user.id}/availability`, { value });
            if (response.data.success) {
                const dataUser = {
                    ...auth.user,
                    availableToWork: value,
                };

                dispatch({
                    type: 'SET_USER',
                    payload: dataUser,
                });

                await setItem(keyLocalStorage, JSON.stringify(dataUser));

                if (!value) {
                    toast.success('Recuerda avisar cuando estés disponible de nuevo.');
                } else {
                    toast.success('Disponible de nuevo para trabajar.');
                }
            }
        } catch (e) {
            toast.error('Ha ocurrido un error, intenta de nuevo.');
        }
    };
    return (
        <Fragment>
            <Breadcrumb title="Perfil" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="profile-details text-center">
                                    {/* <img
                                            src={designer}
                                            alt=""
                                            className="img-fluid img-90 rounded-circle blur-up lazyloaded"
                                        /> */}
                                    {/* <h5 className="f-w-600 f-16 mb-0">John deo</h5> */}
                                    <span>{auth.user.email}</span>
                                </div>
                                <hr />
                                <br />
                                <h3 className="text-center">
                                    {auth.user.availableToWork
                                        ? 'Estás disponible ✅'
                                        : 'Avísanos cuando estés disponible nuevamente 💪'}
                                </h3>
                                <br />
                                <div className="col-lg-12 col-sm-12 mb-4 d-flex justify-content-center">
                                    <button
                                        className={`btn btn-lg btn-${
                                            auth.user.availableToWork ? 'danger' : 'success'
                                        }`}
                                        onClick={() =>
                                            handleAvailabilityToWork(!auth.user.availableToWork)
                                        }
                                    >
                                        {auth.user.availableToWork
                                            ? 'no estaré disponible'
                                            : 'disponible de nuevo'}
                                    </button>
                                </div>
                                {/* <div className="project-status">
                                        <h5 className="f-w-600 f-16">Employee Status</h5>
                                        <div className="media">
                                            <div className="media-body">
                                                <h6>
                                                    Performance{' '}
                                                    <span className="pull-right">80%</span>
                                                </h6>
                                                <div className="progress sm-progress-bar">
                                                    <div
                                                        className="progress-bar bg-primary"
                                                        role="progressbar"
                                                        style={{ width: '90%' }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="media-body">
                                                <h6>
                                                    Overtime <span className="pull-right">60%</span>
                                                </h6>
                                                <div className="progress sm-progress-bar">
                                                    <div
                                                        className="progress-bar bg-secondary"
                                                        role="progressbar"
                                                        style={{ width: '60%' }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="media-body">
                                                <h6>
                                                    Leaves taken{' '}
                                                    <span className="pull-right">50%</span>
                                                </h6>
                                                <div className="progress sm-progress-bar">
                                                    <div
                                                        className="progress-bar bg-danger"
                                                        role="progressbar"
                                                        style={{ width: '50%' }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-8">
                            <div className="card profile-card">
                                <div className="card-body">
                                    <Tabset_profile />
                                </div>
                            </div>
                        </div> */}
                </div>
            </div>
        </Fragment>
    );
}

export default Profile;
