import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Sidebar from './common/sidebar_components/sidebar';
import Right_sidebar from './common/right-sidebar';
import Footer from './common/footer';
import Header from './common/header_components/header';
import { useStateValue } from '../store';

function App(props) {
    const [{ auth }] = useStateValue();
    const [sidebar, setSidebar] = useState(false);

    useEffect(() => {
        if (auth.user) {
            if (sidebar) {
                document.querySelector('.page-main-header').classList.add('open');
                document.querySelector('.page-sidebar').classList.add('open');
            } else {
                document.querySelector('.page-main-header').classList.remove('open');
                document.querySelector('.page-sidebar').classList.remove('open');
            }
        }
    }, [sidebar]);

    if (!auth.user) return <Redirect to={`${process.env.PUBLIC_URL}/login`} />;
    return (
        <div>
            <div className="page-wrapper">
                <Header sidebar={sidebar} setSidebar={setSidebar} />
                <div className="page-body-wrapper">
                    <Sidebar sidebar={sidebar} setSidebar={setSidebar} />
                    <Right_sidebar />
                    <div className="page-body">{props.children}</div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default App;
