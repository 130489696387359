import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';

import Breadcrumb from '../common/breadcrumb';
import { useStateValue } from '../../store';

const ClientInvitation = withRouter(() => {
    const [{ auth }] = useStateValue();
    const {
        handleSubmit: handleSubmitPush,
        register: registerPush,
        errors: errorsPush,
    } = useForm();

    const sendInvitation = (data) => {
        const phoneParsed = data.phone.replace('0', '58');
        const nameParsed = auth.user.name.split(' ')[0];
        const waUrl = `https://wa.me/${phoneParsed}?text=Hola+Sr%28a%29.+${data.name}%2C+un+gusto.%0D%0A%0D%0AMi+nombre+es+${nameParsed}+de+Aguaman.%0D%0A%0D%0ACon+nuestro+servicio+nos+tendr%C3%A1s+que+salir+a+comprar+el+botell%C3%B3n+de+agua.+Te+llevamos+el+botell%C3%B3n+lleno+y+nos+das+el+envase+vac%C3%ADo.+El+servicio+tiene+un+valor+de+12.000+pesos+%28incluye+recarga+y+delivery%29%0D%0A%0D%0APara+m%C3%A1s+informaci%C3%B3n+%C3%B3+hacer+un+pedido+presiona+el+siguiente+enlace.%0D%0A%0D%0Ahttps%3A%2F%2Fwa.me%2Fmessage%2FBLMJ3RC46FRZA1%0D%0A%0D%0AInstagram%0D%0Ahttps%3A%2F%2Finstagram.com%2Faguamandelivery`;

        window.open(waUrl);

        return;
    };

    return (
        <Fragment>
            <Breadcrumb title="Invitar cliente" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form
                                    onSubmit={handleSubmitPush(sendInvitation)}
                                    className="needs-validation user-add"
                                    noValidate=""
                                >
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Primer Nombre
                                        </label>
                                        <input
                                            className="form-control col-xl-4 col-md-7"
                                            type="text"
                                            name="name"
                                            ref={registerPush({
                                                required: true,
                                            })}
                                        />
                                        <p className="message-error-input">
                                            {errorsPush.name &&
                                                errorsPush.name.type === 'required' &&
                                                'Nombre obligatorio'}
                                        </p>
                                    </div>
                                    <div className="form-group row mb-5">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Teléfono
                                        </label>
                                        <input
                                            className="form-control col-xl-5 col-md-7"
                                            type="tel"
                                            name="phone"
                                            ref={registerPush({
                                                required: true,
                                            })}
                                        />
                                        <p className="message-error-input">
                                            {errorsPush.phone &&
                                                errorsPush.phone.type === 'required' &&
                                                'Teléfono obligatorio'}
                                        </p>
                                    </div>
                                    <div className="col-sm-12 text-center mt-5 mb-3">
                                        <button
                                            type="submit"
                                            className="btn btn-success"
                                            onClick={handleSubmitPush(sendInvitation)}
                                        >
                                            Enviar invitación
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});

export default ClientInvitation;
