import authReducer, { initialStateAuth } from './auth';

// Main reducer
const mainReducer = ({ auth, subtitles }, action) => ({
    auth: authReducer(auth, action),
    // anothers reducers
});

// Global initial state with multiple state reducers
// This allow start the state at the App.js
export const mainState = {
    auth: initialStateAuth,
    // anothers initialState
};

export default mainReducer;
