import React, { useEffect, useState, Fragment } from 'react';
import moment from 'moment';
import useForm from 'react-hook-form';
import Select from 'react-select';
import { DateRangePicker } from 'react-dates';
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import Loader from '../common/loader';
import api from '../../config/axios';
import utils from '../../utils/miscUtils';

function Subscriptions(props) {
    const { handleSubmit, register, setValue, clearError } = useForm();
    const [loaded, setLoaded] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [pagination, setPagination] = useState({ page: 1 });
    const [pageTable, setPageTable] = useState(0);
    const [filter, setFilter] = useState(true);

    useEffect(() => {
        fetchSubscriptions(pagination.page);
    }, []);

    const fetchSubscriptions = async page => {
        const { data } = await api.get(`subscription?page=${page}`);
        const subscriptions = parseToDatatable(data.data.rows);

        const dataPagination = {
            ...data.meta.pagination,
            total: data.data.count,
        };
        setSubscriptions(subscriptions);
        setPagination(dataPagination);
        setPageTable(data.meta.pagination.page - 1);
        setLoaded(true);
    };

    const handleFilter = data => {
        const dataRequest = {
            statusTransaction: data.statusTransaction.value,
            email: data.email !== '' ? data.email : undefined,
        };

        filterPurchases(dataRequest);
    };

    const filterPurchases = async dataRequest => {
        const { data } = await api.get(
            `/subscription?page=${1}&statusTransaction=${dataRequest.statusTransaction}`,
        );
        const subscriptions = parseToDatatable(data.data.rows);

        const dataPagination = {
            ...data.meta.pagination,
            total: data.data.count,
        };
        setSubscriptions(subscriptions);
        setPagination(dataPagination);
        setPageTable(data.meta.pagination.page - 1);
        setLoaded(true);
    };

    const cleanPurchases = () => {
        filterPurchases({});
        setValue('email', '');
    };

    const parseToDatatable = array => {
        const parsed = array.map(item => {
            return {
                id: item.id,
                'fecha de pedido': moment(item.createdAt).format('MM/DD/YYYY h:mm a'),
                cliente: item.client.name,
                'forma de pago': item.paymentMethod ? item.paymentMethod.name : 'No aplica',
                cantidad: item.quantity,
                estado: renderBadge(item.status),
            };
        });
        return parsed;
    };

    const renderBadge = status => {
        switch (status) {
            case 'pago_por_confirmar':
                return <span className="badge badge-warning">Pago por confirmar</span>;
            case 'pago_no_confirmado':
                return <span className="badge badge-danger">Pago no confirmado</span>;
            case 'activo':
                return <span className="badge badge-success">Activo</span>;
            case 'finalizado':
                return <span className="badge badge-danger  ">Finalizado</span>;
            default:
                return null;
        }
    };

    const handlePage = page => {
        fetchSubscriptions(page);
    };

    const handleSelectOption = (field, value) => {
        setValue(field, value);
        clearError(field);
    };

    const totalPages = () => Math.ceil(pagination.total / pagination.length);

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );
    return (
        <Fragment>
            <Breadcrumb title="Historial de subscripciones" />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid rents-wrapper">
                <div className="row">
                    <div className="col-sm-12">
                        <div className={`card ${!filter ? 'filter-hidden' : ''}`}>
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4>Filtrar</h4>
                                    <button
                                        className="btn btn-outline"
                                        onClick={() => setFilter(!filter)}
                                    >
                                        {!filter ? 'mostrar' : 'ocultar'} filtros{' '}
                                    </button>
                                </div>
                                <hr />
                                <div className="row pt-3 pb-3">
                                    <div
                                        className="col-lg-5"
                                        style={{ borderRight: '1px solid #eee' }}
                                    >
                                        <div className="form-group mb-3 row d-flex align-items-center">
                                            <label className="col-xl-3 col-sm-4 mb-0">
                                                Estado de la subscripción:
                                            </label>
                                            <div className="col-xl-6 col-sm-8">
                                                <Select
                                                    name="statusTransaction"
                                                    onChange={value =>
                                                        handleSelectOption(
                                                            'statusTransaction',
                                                            value,
                                                        )
                                                    }
                                                    options={[
                                                        {
                                                            value: 'pago_por_confirmar',
                                                            label: 'Pendiente por confirmar',
                                                        },
                                                        {
                                                            value: 'activo',
                                                            label: 'Activo',
                                                        },
                                                        {
                                                            value: 'finalizado',
                                                            label: 'Finalizado',
                                                        },
                                                    ]}
                                                    ref={() =>
                                                        register(
                                                            {
                                                                name: 'statusTransaction',
                                                                type: 'custom',
                                                            },
                                                            {
                                                                required: false,
                                                            },
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3 row d-flex align-items-center">
                                            <label className="col-xl-3 col-sm-4 mb-0">
                                                Nombre de cliente:
                                            </label>
                                            <div className="col-xl-6 col-sm-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="cliente"
                                                    ref={register({
                                                        required: false,
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className="btn btn-light" onClick={cleanPurchases}>
                                        limpiar filtros
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleSubmit(handleFilter)}
                                    >
                                        Aplicar filtros
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="order-list">
                                    <Datatable
                                        class="-striped -highlight"
                                        multiSelectOption={false}
                                        data={subscriptions}
                                        page={pageTable}
                                        pages={totalPages()}
                                        defaultPageSize={pagination.length}
                                        showPageSizeOptions={false}
                                        onPageChange={index => handlePage(index + 1)}
                                        options={false}
                                        manual
                                        getTrProps={(state, rowInfo, column, instance) => ({
                                            onClick: e =>
                                                props.history.push(
                                                    `/subscripciones/${rowInfo.original.id}`,
                                                ),
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    );
}

export default Subscriptions;
