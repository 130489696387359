import React, { useEffect, useState, Fragment } from 'react';
import ReactTable from 'react-table';
import Loader from '../../common/loader';
import api from '../../../config/axios';
import { date, time, dateQuery } from '../../../utils/date';
import miscUtils from '../../../utils/miscUtils';
import { useStateValue } from '../../../store';
import { getTimeFormat, getShortBottlesPurchase } from '../../sales/utils';

const columnsDataTable = (count, role) => [
    {
        Header: role !== 'DELIVERY' ? <b>{`${count} pedidos`}</b> : null,
        id: 'delete',
        accessor: (str) => 'delete',
        Cell: (row) => (
            <div className="wrapper-order-item">
                <div className="wrapper-top-details">
                    <p className="status-order mt-0 mb-2">
                        {row.original.estado}&nbsp;{row.original.carreraLarga}
                    </p>
                    <h4 className="time-order">
                        <i className="fa fa-clock-o"></i> {row.original.entrega}
                    </h4>
                </div>
                <div className="wrapper-quantity-order">
                    <h4 className="quantity-order">{row.original.cantidad}</h4>
                    <span>
                        {row.original.paymentMethod.split('(')[0]}
                        {row.original.couponId && <strong>(cupón)</strong>}
                    </span>
                </div>
                <h4 style={{ color: row.original.observation ? 'red' : '' }}>
                    {row.original.cliente}
                </h4>
                <p className="m-0">{row.original.direccion}</p>
                {/* <div className="wrapper-bottom-description mt-1">
                    <p>{row.original.quantityDescription}</p>

                    {row.original.user ? (
                        <p className="text-right">
                            <i className="fa fa-motorcycle"></i>&nbsp;
                            <strong>{row.original.user.name.split(' ')[0]}</strong>
                        </p>
                    ) : (
                        <p className="text-right">
                            <i className="fa fa-motorcycle"></i>&nbsp;
                            <strong>Por asignar</strong>
                        </p>
                    )}
                </div> */}
            </div>
        ),
        style: {
            textAlign: 'center',
            width: '100%',
        },
        sortable: false,
        width: '100%',
    },
];

const getDatesRange = (type) => {
    let dates = { startDate: null, endDate: null };
    const startDate = new Date();
    const endDate = new Date();

    switch (type) {
        case 'week':
            endDate.setDate(endDate.getDate() + 1);
            dates.endDate = dateQuery(endDate);
            startDate.setDate(startDate.getDate() - 7);
            dates.startDate = dateQuery(startDate);
            break;
        case 'currently-month':
            dates.startDate = dateQuery(new Date(startDate.getFullYear(), startDate.getMonth(), 1));
            dates.endDate = dateQuery(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0));
            break;
        case 'last-month':
            dates.startDate = dateQuery(
                new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1),
            );
            dates.endDate = dateQuery(new Date(endDate.getFullYear(), endDate.getMonth(), 0));
            break;
    }
    return dates;
};

const getLastTransaction = (transactions) =>
    transactions.reduce((a, b) => (a.createdAt > b.createdAt ? a : b));

const getFirstAndLastDateOfTheWeek = () => {
    var curr = new Date();
    curr.setHours(0, 0, 0, 0); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first + 1));
    var lastday = new Date(curr.setDate(last + 1));

    return {
        first: firstday,
        last: lastday,
    };
};

function Orders(props) {
    const userId = props.match.params.userId;
    const [{ auth }] = useStateValue();
    const [loaded, setLoaded] = useState(false);
    const [orders, setOrders] = useState([]);
    const [pagination, setPagination] = useState({ page: 1 });
    const [pageTable, setPageTable] = useState(0);
    const [filterOrders, setFilterOrders] = useState('week');
    const { first: mondayWeek, last: sundayWeek } = getFirstAndLastDateOfTheWeek();

    useEffect(() => {
        setLoaded(false);
        const { startDate, endDate } = getDatesRange(filterOrders);
        fetchOrders(pagination.page, startDate, endDate);
    }, [filterOrders]);

    const fetchOrders = async (page, startDate, endDate) => {
        const { data } = await api.get(
            `purchase/users?page=${page}&startDate=${startDate}&endDate=${endDate}&userId=${userId}`,
        );

        // mostrar todas las ordenes (creadas, enviadas y entregadas)
        const orders = parseToDatatable(data.data);

        const dataPagination = {
            ...data.meta.pagination,
            total: data.data.length,
        };

        setOrders(orders);
        setPagination(dataPagination);
        setPageTable(data.meta.pagination.page - 1);

        setLoaded(true);
    };

    const parseToDatatable = (array) => {
        const parsed = array.map((item) => {
            const lastTransaction = item.transactions.reduce((a, b) =>
                a.createdAt > b.createdAt ? a : b,
            );

            const firstTransaction = item.transactions.reduce((a, b) =>
                b.createdAt > a.createdAt ? a : b,
            );

            const productRecargas = item.products.find((item) => item.name === 'Recarga');

            const quantityOrder = productRecargas
                ? getShortBottlesPurchase(
                      productRecargas.purchaseProducts.quantity,
                      item.address.newBottles,
                  )
                : '';
            const itemFormatted = {
                id: item.id,
                estado: renderBadge(lastTransaction.name),
                carreraLarga: item.address.longway ? renderLongway() : null,
                entrega: `${
                    item.delivery_time
                        ? getTimeFormat(item.delivery_time)
                        : `${time(firstTransaction.createdAt)} - Express`
                }`,
                'fecha de pedido': `${date(item.createdAt)} ${time(firstTransaction.createdAt)}`,
                cantidad: miscUtils.renderTextPedido(item),
                quantityDescription: quantityOrder,
                cliente: item.client ? item.client.name : `${item.nameQuick} (CNR)`,
                direccion: item.address ? item.address.street1 : item.addressQuick,
                user: item.user,
                paymentMethod: item.paymentMethod.name,
                observation: purchaseWithNotes(item.oldPurchases),
                couponId: item.couponId,
            };

            return itemFormatted;
        });
        return parsed;
    };

    const renderBadge = (status) => {
        switch (status) {
            case 'Pedido Creado':
                return <span className="badge badge-warning">Creado</span>;
            case 'Pedido Cancelado':
                return <span className="badge badge-danger">Cancelado</span>;
            case 'Pedido Enviado':
                return <span className="badge badge-info">Enviado</span>;
            case 'Pedido Recibido':
                return <span className="badge badge-success">Recibido</span>;
            case 'Pedido Entregado':
                return <span className="badge badge-success">Entregado</span>;
            case 'Pedido Rechazado':
                return <span className="badge badge-danger">Rechazado</span>;
            case 'Pago Confirmado':
                return <span className="badge badge-warning">Pago Confirmado</span>;
            default:
                return null;
        }
    };

    const renderLongway = () => <span className="badge badge-info">Larga</span>;

    const handlePage = (page) => {
        setLoaded(false);
        const { startDate, endDate } = getDatesRange(filterOrders);
        fetchOrders(page, startDate, endDate);
    };

    const totalPages = () => Math.ceil(pagination.total / pagination.length);

    const purchaseWithNotes = (array) => {
        const observations = array.filter((item) =>
            item.transactions.find((transaction) => !!transaction.observation),
        );

        return observations.length > 0;
    };

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    const columns = columnsDataTable(pagination.total, auth.user.role);

    return (
        <Fragment>
            <div className="container-fluid rents-wrapper">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div id="basicScenario" className="order-list">
                                    <ReactTable
                                        columns={columns}
                                        className="-striped "
                                        multiSelectOption={false}
                                        data={orders}
                                        page={pageTable}
                                        pages={totalPages()}
                                        defaultPageSize={columns.length}
                                        showPageSizeOptions={false}
                                        onPageChange={(index) => handlePage(index + 1)}
                                        manual
                                        nextText="Siguiente"
                                        previousText="Anterior"
                                        getTrProps={(state, rowInfo, column, instance) => ({
                                            onClick: (e) =>
                                                props.history.push(
                                                    `/recargas/detalle/${rowInfo.original.id}`,
                                                ),
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Orders;
