import React, { useEffect, useReducer, Fragment } from 'react';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import ReactTable from 'react-table';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';

import Breadcrumb from '../common/breadcrumb';
import Loader from '../common/loader';
import api from '../../config/axios';

const ModalForm = (props) => {
    const {
        franchises,
        type,
        visible,
        handleSubmit,
        handleClose,
        register,
        clearError,
        setValue,
        errors,
        data,
    } = props;

    const handleSelectOption = (field, value) => {
        setValue(field, value);
        clearError(field);
    };

    return (
        <Modal
            open={visible}
            onClose={handleClose}
            styles={{ modal: { width: 400 }, closeButton: { cursor: 'pointer' } }}
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {type} Usuario
                </h5>
            </div>
            <div className="modal-body">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name" className="col-form-label">
                            Nombre y apellido:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            ref={register({
                                required: true,
                            })}
                            defaultValue={data && data.name}
                        />
                        {errors.name && errors.name.type === 'required' && (
                            <p className="message-error-input">Nombre es obligatorio</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="name" className="col-form-label">
                            Teléfono:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="phone_number"
                            ref={register({
                                required: true,
                            })}
                            defaultValue={data && data.phone_number}
                        />
                        {errors.phone_number && errors.phone_number.type === 'required' && (
                            <p className="message-error-input">Teléfono es obligatorio</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="name" className="col-form-label">
                            Correo electrónico:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="email"
                            ref={register({
                                required: true,
                            })}
                            defaultValue={data && data.email}
                        />
                        {errors.email && errors.email.type === 'required' && (
                            <p className="message-error-input">Email es obligatorio</p>
                        )}
                    </div>
                    {type !== 'Modificar' && (
                        <div className="form-group">
                            <label htmlFor="name" className="col-form-label">
                                Contraseña:
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="password"
                                ref={register({
                                    required: true,
                                })}
                                defaultValue={data && data.password}
                            />
                            {errors.password && errors.password.type === 'required' && (
                                <p className="message-error-input">Contraseña es obligatoria</p>
                            )}
                        </div>
                    )}
                    {type !== 'Modificar' && (
                        <div className="form-group">
                            <label htmlFor="description" className="col-form-label">
                                Franquicia:
                            </label>
                            <Select
                                name="franchise"
                                onChange={(value) => handleSelectOption('franchise', value)}
                                placeholder="Seleccione la franquicia"
                                defaultValue={
                                    data && {
                                        value: data.franchise,
                                        label: data.franchise,
                                    }
                                }
                                options={franchises}
                                ref={() =>
                                    register(
                                        {
                                            name: 'franchise',
                                            type: 'custom',
                                        },
                                        {
                                            required: true,
                                        },
                                    )
                                }
                            />
                            {errors.franchise && errors.franchise.type === 'required' && (
                                <p className="message-error-input">Franquicia es requerido</p>
                            )}
                        </div>
                    )}
                    {type !== 'Modificar' && (
                        <div className="form-group">
                            <label htmlFor="description" className="col-form-label">
                                Rol:
                            </label>
                            <Select
                                name="role"
                                onChange={(value) => handleSelectOption('role', value)}
                                placeholder="Seleccione el rol"
                                defaultValue={
                                    data && {
                                        value: data.moneda,
                                        label: data.moneda,
                                    }
                                }
                                options={[
                                    {
                                        value: 'FRANCHISE',
                                        label: 'FRANCHISE',
                                    },
                                    {
                                        value: 'DELIVERY',
                                        label: 'DELIVERY',
                                    },
                                ]}
                                ref={() =>
                                    register(
                                        {
                                            name: 'role',
                                            type: 'custom',
                                        },
                                        {
                                            required: true,
                                        },
                                    )
                                }
                            />
                            {errors.role && errors.role.type === 'required' && (
                                <p className="message-error-input">Rol es requerido</p>
                            )}
                        </div>
                    )}
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                    {type}
                </button>
            </div>
        </Modal>
    );
};

const ModalDelete = (props) => {
    const { visible, handleSubmit, handleClose } = props;
    return (
        <Modal open={visible} onClose={handleClose}>
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Eliminar usuario
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <p>Está seguro de eliminar esta usuario?</p>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                    Sí
                </button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                    No
                </button>
            </div>
        </Modal>
    );
};

const ModalAvailability = (props) => {
    const { visible, handleSubmit, handleClose, data } = props;

    const handleOption = (value) => {
        handleSubmit(data.userId, data.name, !data.availableToWork, value);
    };
    if (!data) return null;
    return (
        <Modal open={visible} onClose={handleClose}>
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Activar/desactivar usuario
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <p>
                        {data.availableToWork ? 'Desactivar' : 'Activar'} a {data.name}
                    </p>
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleOption(false)}
                >
                    Sin notificar
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleOption(true)}
                >
                    {data.availableToWork ? 'Desactivar' : 'Activar'}
                </button>
            </div>
        </Modal>
    );
};

const columnsDataTable = () => [
    {
        Header: <b>Disp.</b>,
        width: 120,
        id: 'edit',
        accessor: (str) => 'edit',
        Cell: (row) => (
            <div>
                <span
                    onClick={(e) => {
                        e.stopPropagation();
                        row.original.actionAvailable(row.original);
                    }}
                >
                    <i
                        className={`fa ${row.original.disponible ? 'fa-check' : 'fa-times'}`}
                        style={{
                            width: 35,
                            fontSize: 20,
                            padding: 11,
                            color: row.original.disponible ? 'green' : 'red',
                            cursor: 'pointer',
                        }}
                    ></i>
                </span>
            </div>
        ),
        style: {
            textAlign: 'center',
        },
        sortable: false,
    },
    {
        Header: <b>Nombre</b>,
        accessor: 'name',
        Cell: null,
        width: 220,
    },
    {
        Header: <b>Teléfono</b>,
        accessor: 'phone_number',
        Cell: null,
        width: 180,
    },
    {
        Header: <b>Email</b>,
        accessor: 'email',
        Cell: null,
        width: 300,
    },
    {
        Header: <b>Rol</b>,
        accessor: 'rol',
        Cell: null,
        width: 120,
    },
    {
        Header: <b>Franquicia</b>,
        accessor: 'franquicia',
        Cell: null,
    },
];

function Users(props) {
    const { handleSubmit, register, errors, clearError, setValue } = useForm();
    const [stateUsers, setStateUsers] = useReducer((s, a) => ({ ...s, ...a }), {
        resolved: false,
        data: null,
        pagination: {
            page: 1,
        },
        loading: true,
        error: null,
        user: null,
        users: [],
        franchises: [],
    });
    const [stateModal, setStateModal] = useReducer((s, a) => ({ ...s, ...a }), {
        visibleCreate: false,
        visibleUpdate: false,
        visibleDelete: false,
        visibleAvailability: false,
        loading: false,
        data: null,
    });

    useEffect(() => {
        Promise.all([fetchUsers(stateUsers.pagination.page), fetchFranchises()]).then((values) => {
            const users = values[0].users;
            const pagination = values[0].pagination;
            const franchises = values[1];

            setStateUsers({
                resolved: true,
                loading: false,
                pagination,
                users,
                franchises,
            });
        });
    }, []);

    const fetchUsers = async (page) => {
        const { data } = await api.get(`/user?page=${page}`);
        const users = parseToDatatable(data.data.rows);

        const dataPagination = {
            ...data.meta.pagination,
            total: data.data.count,
        };

        return { pagination: dataPagination, users };
    };

    const fetchFranchises = async () => {
        const { data } = await api.get(`/franchise`);

        return data.data.rows;
    };

    const parseToDatatable = (array) => {
        const parsed = array.map((item) => {
            return {
                email: item.email,
                name: item.name,
                phone_number: item.phone_number,
                franquicia: item.franchise ? item.franchise.name : 'ADMIN',
                rol: item.role,
                disponible: item.availableToWork,
                actionAvailable: () =>
                    onOpenModalAvailability(item.id, item.name, item.availableToWork),
            };
        });
        return parsed;
    };

    const addUser = async (data) => {
        const newUser = {
            ...data,
            franchiseId: data.franchise.value,
            role: data.role.value,
        };
        setStateModal({
            ...stateModal,
            loading: true,
        });
        try {
            await api.post('/user', newUser);
            setStateModal({
                ...stateModal,
                loading: false,
                visibleCreate: false,
            });
            toast.success('Creado con éxito');
        } catch (error) {
            setStateModal({
                ...stateModal,
                loading: false,
            });
        }
    };

    const updateUser = async (data) => {
        setStateModal({
            ...stateModal,
            loading: true,
        });
        const newUser = {
            ...data,
            franchiseId: data.franchise.id,
            role: data.role.value,
        };

        try {
            await api.put(`/user/${stateModal.data.id}`, newUser);
            fetchUsers(1);
            setStateModal({
                ...stateModal,
                loading: false,
                visibleUpdate: false,
            });
            toast.success('Actualizado con éxito');
        } catch (error) {
            setStateModal({
                ...stateModal,
                loading: false,
            });
        }
    };

    const deleteUser = async () => {
        setStateModal({
            ...stateModal,
            loading: true,
        });
        try {
            await api.delete(`/user/${stateModal.data.id}`);
            fetchUsers(1);
            setStateModal({
                ...stateModal,
                loading: false,
                visibleDelete: false,
            });
            toast.info('Eliminado con éxito');
        } catch (error) {
            setStateModal({
                ...stateModal,
                loading: false,
            });
        }
    };

    const handleDeliveryHistory = async (userId, action, description) => {
        const data = {
            userId,
            action,
            description,
        };

        try {
            await api.post(`/delivery_user_history`, data);
        } catch (e) {
            console.log(e);
        }
    };

    const handleAvailability = async (userId, userName, status, sendPush) => {
        try {
            await api.post(`/user/${userId}/availability`, { value: status, sendPush });
            if (!status) {
                await handleDeliveryHistory(
                    userId,
                    'INACTIVE',
                    `Administrador desactivó a ${userName}(${userId}) desde el panel administrativo`,
                );
            } else {
                await handleDeliveryHistory(
                    userId,
                    'ACTIVE',
                    `Administrador activó a ${userName}(${userId}) desde el panel administrativo`,
                );
            }

            toast.info('Valor modificado con éxito');
            window.location.reload();
        } catch (error) {}
    };

    const handlePage = (page) => {
        fetchUsers(page);
    };

    const onOpenModal = () => {
        setStateModal({
            ...stateModal,
            visibleCreate: true,
        });
    };

    const onCloseModal = () => {
        setStateModal({
            ...stateModal,
            data: null,
            visibleCreate: false,
        });
    };

    const onOpenModalUpdate = (info) => {
        setStateModal({
            ...stateModal,
            data: info,
            visibleUpdate: true,
        });
    };

    const onCloseModalUpdate = () => {
        setStateModal({
            ...stateModal,
            data: null,
            visibleUpdate: false,
        });
    };

    const onOpenModalAvailability = (userId, name, availableToWork) => {
        setStateModal({
            ...stateModal,
            data: {
                userId,
                name,
                availableToWork,
            },
            visibleAvailability: true,
        });
    };

    const onCloseModalDelete = () => {
        setStateModal({
            ...stateModal,
            data: null,
            visibleDelete: false,
        });
    };

    const onCloseModalAvailability = () => {
        setStateModal({
            ...stateModal,
            data: null,
            visibleAvailability: false,
        });
    };

    const totalPages = () => Math.ceil(stateUsers.pagination.total / stateUsers.pagination.length);

    if (!stateUsers.resolved)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    const franchisesParsed = stateUsers.franchises.map((e) => ({ value: e.id, label: e.name }));
    const columnsUsers = columnsDataTable();
    return (
        <Fragment>
            {/* <!-- Container-fluid starts--> */}
            <Breadcrumb title="Usuarios" />
            <div className="container-fluid rents-wrapper pt-2">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="btn-popup pull-right">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"
                                    >
                                        Nuevo usuario
                                    </button>
                                </div>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="order-list">
                                    <ReactTable
                                        columns={columnsUsers}
                                        className="-striped "
                                        multiSelectOption={false}
                                        data={stateUsers.users}
                                        page={stateUsers.pagination.page - 1}
                                        pages={totalPages()}
                                        defaultPageSize={stateUsers.pagination.length}
                                        showPageSizeOptions={false}
                                        onPageChange={(index) => handlePage(index + 1)}
                                        options={false}
                                        manual
                                        getTrProps={(state, rowInfo, column, instance) => ({
                                            onClick: (e) => onOpenModalUpdate(rowInfo.original),
                                        })}
                                        nextText="Siguiente"
                                        previousText="Anterior"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalForm
                type={'Agregar'}
                visible={stateModal.visibleCreate}
                handleSubmit={handleSubmit(addUser)}
                handleClose={onCloseModal}
                register={register}
                errors={errors}
                clearError={clearError}
                setValue={setValue}
                franchises={franchisesParsed}
            />
            <ModalForm
                type={'Modificar'}
                data={stateModal.data}
                visible={stateModal.visibleUpdate}
                handleSubmit={handleSubmit(updateUser)}
                handleClose={onCloseModalUpdate}
                register={register}
                errors={errors}
                clearError={clearError}
                setValue={setValue}
                franchises={franchisesParsed}
            />
            <ModalDelete
                visible={stateModal.visibleDelete}
                handleSubmit={deleteUser}
                handleClose={onCloseModalDelete}
            />
            <ModalAvailability
                visible={stateModal.visibleAvailability}
                data={stateModal.data}
                handleSubmit={handleAvailability}
                handleClose={onCloseModalAvailability}
            />
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    );
}

export default Users;
