import React, { useState, useEffect, Fragment } from 'react';
import Modal from 'react-responsive-modal';
import useForm from 'react-hook-form';
import moment from 'moment';
import { toast } from 'react-toastify';

import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import Loader from '../common/loader';
import api from '../../config/axios';

const ModalForm = (props) => {
    const { type, visible, handleSubmit, handleClose, register, errors, data } = props;

    return (
        <Modal
            open={visible}
            onClose={handleClose}
            styles={{ modal: { width: 350 }, closeButton: { cursor: 'pointer' } }}
        >
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {type} Forma de entrega
                </h5>
            </div>
            <div className="modal-body">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name" className="col-form-label">
                            Nombre:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            ref={register({
                                required: true,
                            })}
                            defaultValue={data && data.nombre}
                        />
                        {errors.name && errors.name.type === 'required' && (
                            <p className="message-error-input">Nombre es obligatorio</p>
                        )}
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="name" className="col-form-label">
                            Descripción:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="subname"
                            ref={register({
                                required: false,
                            })}
                            defaultValue={data && data.subname}
                        />
                    </div> */}
                    <div className="form-group">
                        <label htmlFor="name" className="col-form-label">
                            Tiempo de entrega:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="time"
                            ref={register({
                                required: true,
                            })}
                            defaultValue={data && data['Tiempo entrega']}
                        />
                        {errors.time && errors.time.type === 'required' && (
                            <p className="message-error-input">Tiempo de entrega es obligatorio</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="name" className="col-form-label">
                            Hora de apertura:
                        </label>
                        <input
                            type="time"
                            className="form-control"
                            name="startTime"
                            ref={register({
                                required: true,
                            })}
                            defaultValue={data && data['Hora apertura']}
                        />
                        {errors.startTime && errors.startTime.type === 'required' && (
                            <p className="message-error-input">Tiempo de entrega es obligatorio</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="name" className="col-form-label">
                            Hora de cierre:
                        </label>
                        <input
                            type="time"
                            className="form-control"
                            name="endTime"
                            ref={register({
                                required: true,
                            })}
                            defaultValue={data && data['Hora cierre']}
                        />
                        {errors.endTime && errors.endTime.type === 'required' && (
                            <p className="message-error-input">Tiempo de entrega es obligatorio</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="name" className="col-form-label">
                            Precio USD:
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            name="amount_usd"
                            ref={register({
                                required: true,
                                min: 0,
                            })}
                            defaultValue={data && data['Monto USD']}
                        />
                        {errors.amount_usd && errors.amount_usd.type === 'required' && (
                            <p className="message-error-input">Monto en COP es obligatorio</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="name" className="col-form-label">
                            Precio COP:
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            name="amount_cop"
                            ref={register({
                                required: true,
                                min: 0,
                            })}
                            defaultValue={data && data['Monto COP']}
                        />
                        {errors.amount_cop && errors.amount_cop.type === 'required' && (
                            <p className="message-error-input">Monto en USD es obligatorio</p>
                        )}
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                    {type}
                </button>
            </div>
        </Modal>
    );
};

const ModalDelete = (props) => {
    const { visible, handleSubmit, handleClose } = props;
    return (
        <Modal open={visible} onClose={handleClose}>
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Eliminar forma de entrega
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <p>Está seguro de eliminar esta forma de entrega?</p>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                    Sí
                </button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                    No
                </button>
            </div>
        </Modal>
    );
};

function Payments() {
    const { handleSubmit, register, errors, clearError, setValue } = useForm();

    const [loaded, setLoaded] = useState(false);
    const [open, setOpen] = useState(false);
    const [visibleModalDelete, setVisibleModalDelete] = useState(false);
    const [visibleModalUpdate, setVisibleModalUpdate] = useState(false);
    const [deliveries, setDeliveries] = useState([]);
    const [pagination, setPagination] = useState({ page: 1 });
    const [pageTable, setPageTable] = useState(0);
    const [infoDelivery, setDelivery] = useState(null);

    useEffect(() => {
        fetchDeliveries(pagination.page);
    }, []);

    const fetchDeliveries = async (page) => {
        const { data } = await api.get(`/delivery/list`);
        const deliveries = parseToDatatable(data.data.rows);

        const dataPagination = {
            ...data.meta.pagination,
            total: data.data.count,
        };
        setDeliveries(deliveries);
        setPagination(dataPagination);
        setPageTable(data.meta.pagination.page - 1);
        setLoaded(true);
    };

    const parseToDatatable = (array) => {
        const parsed = array.map((item) => {
            return {
                id: item.id,
                nombre: item.name,
                'Tiempo entrega': item.time,
                'Hora apertura': item.startTime,
                'Hora cierre': item.endTime,
                'Monto USD': item.amount_usd,
                'Monto COP': item.amount_cop,
                'Fecha creación': moment(item.createdAt).format('DD/MM/YYYY'),
            };
        });
        return parsed;
    };

    const addDelivery = async (data) => {
        setLoaded(false);
        try {
            await api.post('/delivery', data);
            fetchDeliveries(1);
            onCloseModal();
            setLoaded(true);
            toast.info('Creado con éxito');
        } catch (error) {
            setLoaded(true);
        }
    };

    const updateDelivery = async (data) => {
        setLoaded(false);
        try {
            await api.put(`/delivery/${infoDelivery.id}`, data);
            fetchDeliveries(1);
            onCloseModalUpdate();
            setLoaded(true);
            toast.info('Actualizado con éxito');
        } catch (error) {
            onCloseModalUpdate();
            setLoaded(true);
            toast.error('Ha ocurrido un error');
        }
    };

    const deleteDelivery = async () => {
        setLoaded(false);
        try {
            await api.delete(`/delivery/${infoDelivery.id}`);
            fetchDeliveries(1);
            onCloseModalDelete();
            setLoaded(true);
            toast.success('Eliminado con éxito');
        } catch (error) {
            onCloseModalDelete();
            setLoaded(true);
            toast.error('Ha ocurrido un error');
        }
    };

    const handlePage = (page) => {
        fetchDeliveries(page);
    };

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const onOpenModalUpdate = (info) => {
        setDelivery(info);
        setVisibleModalUpdate(true);
    };

    const onCloseModalUpdate = () => {
        setVisibleModalUpdate(false);
    };

    const onOpenModalDelete = (info) => {
        setDelivery(info);
        setVisibleModalDelete(true);
    };

    const onCloseModalDelete = () => {
        setVisibleModalDelete(false);
    };

    const totalPages = () => Math.ceil(pagination.total / pagination.length);

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );
    return (
        <Fragment>
            <Breadcrumb title="Formas de entrega" />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="btn-popup pull-right">
                                    {/* <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"
                                    >
                                        Agregar forma de entrega
                                    </button> */}
                                    <ModalForm
                                        type={'Agregar'}
                                        visible={open}
                                        handleSubmit={handleSubmit(addDelivery)}
                                        handleClose={onCloseModal}
                                        register={register}
                                        errors={errors}
                                        clearError={clearError}
                                        setValue={setValue}
                                    />
                                    <ModalForm
                                        type={'Modificar'}
                                        data={infoDelivery}
                                        visible={visibleModalUpdate}
                                        handleSubmit={handleSubmit(updateDelivery)}
                                        handleClose={onCloseModalUpdate}
                                        register={register}
                                        errors={errors}
                                    />
                                    <ModalDelete
                                        visible={visibleModalDelete}
                                        handleSubmit={deleteDelivery}
                                        handleClose={onCloseModalDelete}
                                    />
                                </div>
                                <div className="clearfix"></div>
                                <div className="table-boxes">
                                    <Datatable
                                        class="-striped -highlight"
                                        multiSelectOption={false}
                                        data={deliveries}
                                        page={pageTable}
                                        pages={totalPages()}
                                        defaultPageSize={pagination.length}
                                        showPageSizeOptions={false}
                                        onPageChange={(index) => handlePage(index + 1)}
                                        onEdit={onOpenModalUpdate}
                                        onDelete={onOpenModalDelete}
                                        options={true}
                                        manual
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    );
}

export default Payments;
