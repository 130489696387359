import React, { Fragment, useState } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User } from 'react-feather';
import { withRouter } from 'react-router-dom';
import Pace from 'react-pace-progress';
import useForm from 'react-hook-form';
import axios from '../../config/axios';
import { useStateValue } from '../../store';
import { setItem, keyLocalStorage } from '../../config/localStorage';

const LoginTabset = withRouter(({ history }) => {
    const { handleSubmit, register } = useForm();

    const [{}, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false);
    const [errorRequest, setErrorsRequest] = useState(null);

    const handleLogin = (data, e) => {
        e.preventDefault();
        setLoading(true);

        axios
            .post('/user/login', data)
            .then(async ({ data }) => {
                const dataUser = {
                    token: data.data.token,
                    ...data.data.user,
                };

                setLoading(false);

                dispatch({
                    type: 'SET_USER',
                    payload: dataUser,
                });

                await setItem(keyLocalStorage, JSON.stringify(dataUser));

                window.location.href = '/recargas';
            })
            .catch(({ response }) => {
                setLoading(false);
                setErrorsRequest(response.data.errors);
            });
    };

    return (
        <div>
            <Fragment>
                <Tabs>
                    <TabList className="nav nav-tabs tab-coupon">
                        <Tab className="nav-link">
                            <User />
                            Iniciar sesión
                        </Tab>
                    </TabList>

                    <TabPanel>
                        <form
                            className="form-horizontal auth-form"
                            onSubmit={handleSubmit(handleLogin)}
                        >
                            <div className="form-group">
                                <input
                                    required="Email obligatorio"
                                    type="email"
                                    className="form-control"
                                    placeholder="Correo eléctronico"
                                    id="exampleInputEmail1"
                                    name="username"
                                    ref={register({
                                        required: true,
                                        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
                                    })}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    required="Contraseña obligatoria"
                                    type="password"
                                    className="form-control"
                                    placeholder="Contraseña"
                                    name="password"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                            </div>
                            <div className="form-button">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    onClick={handleSubmit(handleLogin)}
                                >
                                    Entrar
                                </button>
                            </div>
                            <div className="form-footer"></div>
                            <div className="wrapper-errors">
                                {errorRequest
                                    ? errorRequest.map((error) => (
                                          <p className="message-error-input">
                                              <i className="fa fa-exclamation-triangle"></i>
                                              &nbsp;{error.msg}
                                          </p>
                                      ))
                                    : null}
                            </div>
                        </form>
                    </TabPanel>
                </Tabs>
            </Fragment>
            {loading ? <Pace color="#ff4c3b" /> : null}
        </div>
    );
});

export default withRouter(LoginTabset);
