import React, { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { MENUITEMSADMIN, MENUITEMSFRANCHISE, MENUITEMSDELIVERY } from '../../../constants/menu';
import { useStateValue } from '../../../store';
import api from '../../../config/axios';

// image import
import logo from '../../../assets/images/dashboard/logo-short.png';

function Sidebar(props) {
    const [{ auth }] = useStateValue();
    const [state, setState] = useState({ selectedPath: '1', mainmenu: [] });
    const [user, setUser] = useState(null);

    let MENURENDER = MENUITEMSDELIVERY;
    switch (auth.user.role) {
        case 'ADMIN':
            MENURENDER = MENUITEMSADMIN;
            break;
        case 'FRANCHISE':
            MENURENDER = MENUITEMSFRANCHISE;
            break;
        case 'DELIVERY':
            MENURENDER = MENUITEMSDELIVERY;
            break;
        default:
            MENURENDER = MENUITEMSDELIVERY;
            break;
    }

    useEffect(() => {
        setState({
            mainmenu: MENURENDER,
        });
        var currentUrl = window.location.pathname;

        state.mainmenu.filter((items) => {
            if (!items.children) {
                if (items.path === currentUrl) setNavActive(items);
                return false;
            }
            items.children.filter((subItems) => {
                if (subItems.path === currentUrl) setNavActive(subItems);
                if (!subItems.children) return false;
                subItems.children.filter((subSubItems) => {
                    if (subSubItems.path === currentUrl) setNavActive(subSubItems);
                });
            });
        });

        if (auth.user.role === 'DELIVERY') {
            fetchUserInfo(auth.user.id).then((response) => {
                setUser(response);
            });
        }
    }, []);

    const fetchUserInfo = async (userId) => {
        try {
            const response = await api.get(`/user/${userId}`);

            return response.data.data;
        } catch (e) {
            throw new Error(e);
        }
    };

    const handleDeliveryHistory = async (userId, action, description) => {
        const data = {
            userId,
            action,
            description,
        };

        try {
            await api.post(`/delivery_user_history`, data);
        } catch (e) {
            console.log(e);
        }
    };

    const handleAvailabilityToWork = async (value) => {
        try {
            const response = await api.post(`/user/${auth.user.id}/availability`, { value });
            if (response.data.success) {
                if (!value) {
                    await handleDeliveryHistory(
                        auth.user.id,
                        'INACTIVE',
                        `${auth.user.name} se ha inactivado desde su teléfono celular`,
                    );
                    toast.success('Recuerda avisar cuando estés disponible de nuevo.');
                } else {
                    toast.success('Disponible de nuevo para trabajar.');
                    await handleDeliveryHistory(
                        auth.user.id,
                        'ACTIVE',
                        `${auth.user.name} se ha activado desde su teléfono celular`,
                    );
                }

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        } catch (e) {
            toast.error('Ha ocurrido un error, intenta de nuevo.');
        }
    };

    const setNavActive = (item) => {
        MENURENDER.filter((menuItem) => {
            if (menuItem != item) menuItem.active = false;
            if (menuItem.children && menuItem.children.includes(item)) menuItem.active = true;
            if (menuItem.children) {
                menuItem.children.filter((submenuItems) => {
                    if (submenuItems != item) {
                        submenuItems.active = false;
                    }
                    if (submenuItems.children) {
                        submenuItems.children.map((childItem) => {
                            childItem.active = false;
                        });
                        if (submenuItems.children.includes(item)) {
                            submenuItems.active = true;
                            menuItem.active = true;
                        }
                    }
                });
            }
        });
        item.active = !item.active;

        setState({
            mainmenu: MENURENDER,
        });
    };

    const handleSidebar = (menuItem) => {
        setNavActive(menuItem);
        if (window.innerWidth < 768) {
            props.setSidebar(true);
        }
    };

    const mainmenu = state.mainmenu.map((menuItem, i) => (
        <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
            {menuItem.sidebartitle ? (
                <div className="sidebar-title">{menuItem.sidebartitle}</div>
            ) : (
                ''
            )}
            {menuItem.type === 'sub' ? (
                <a className="sidebar-header " onClick={() => setNavActive(menuItem)}>
                    <menuItem.icon />
                    <span>{menuItem.title}</span>
                    <i className="fa fa-angle-right pull-right"></i>
                </a>
            ) : (
                ''
            )}
            {menuItem.type === 'link' ? (
                <Link
                    to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                    className={`sidebar-header ${menuItem.active ? 'active' : ''}`}
                    onClick={() => handleSidebar(menuItem)}
                >
                    <menuItem.icon />
                    <span>{menuItem.title}</span>
                    {menuItem.children ? <i className="fa fa-angle-right pull-right"></i> : ''}
                </Link>
            ) : (
                ''
            )}
            {menuItem.children ? (
                <ul
                    className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                    style={
                        menuItem.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}
                    }
                >
                    {menuItem.children.map((childrenItem, index) => (
                        <li
                            key={index}
                            className={
                                childrenItem.children ? (childrenItem.active ? 'active' : '') : ''
                            }
                        >
                            {childrenItem.type === 'sub' ? (
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => setNavActive(childrenItem)}
                                >
                                    <i className="fa fa-circle"></i>
                                    {childrenItem.title}{' '}
                                    <i className="fa fa-angle-right pull-right"></i>
                                </a>
                            ) : (
                                ''
                            )}

                            {childrenItem.type === 'link' ? (
                                <Link
                                    to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                    className={childrenItem.active ? 'active' : ''}
                                    onClick={() => setNavActive(childrenItem)}
                                >
                                    <i className="fa fa-circle"></i>
                                    {childrenItem.title}{' '}
                                </Link>
                            ) : (
                                ''
                            )}
                            {childrenItem.children ? (
                                <ul
                                    className={`sidebar-submenu ${
                                        childrenItem.active ? 'menu-open' : 'active'
                                    }`}
                                >
                                    {childrenItem.children.map((childrenSubItem, key) => (
                                        <li
                                            className={childrenSubItem.active ? 'active' : ''}
                                            key={key}
                                        >
                                            {childrenSubItem.type === 'link' ? (
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                    className={
                                                        childrenSubItem.active ? 'active' : ''
                                                    }
                                                    onClick={() => setNavActive(childrenSubItem)}
                                                >
                                                    <i className="fa fa-circle"></i>
                                                    {childrenSubItem.title}
                                                </Link>
                                            ) : (
                                                ''
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                ''
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                ''
            )}
        </li>
    ));

    const availableToWork = user && user.availableToWork;
    return (
        <Fragment>
            <div className="page-sidebar">
                <div className="main-header-left d-none d-lg-block">
                    <div className="logo-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                            <img
                                className="blur-up lazyloaded logo-sidebar-main"
                                src={logo}
                                alt=""
                            />
                        </Link>
                    </div>
                </div>
                <div className="sidebar custom-scrollbar">
                    {/* <User_panel /> */}
                    <ul className="sidebar-menu">{mainmenu}</ul>
                    {auth.user.role === 'DELIVERY' && user && (
                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                bottom: 50,
                                width: '100%',
                                textAlign: 'center',
                            }}
                        >
                            <p className={availableToWork ? 'text-success' : 'text-danger'}>
                                <strong>Estás {availableToWork ? 'Activo' : 'Inactivo'}</strong>
                                <i
                                    className={`fa ${
                                        availableToWork
                                            ? 'fa-check text-success'
                                            : 'fa-times text-danger'
                                    }`}
                                />
                            </p>
                            <br />
                            <span style={{ fontSize: 11 }}>
                                Pulsa el botón para {availableToWork ? 'desactivarte' : 'activarte'}
                            </span>
                            <button
                                style={{ marginTop: 16 }}
                                className={`btn btn-sm btn-${
                                    availableToWork ? 'danger' : 'success'
                                }`}
                                onClick={() => handleAvailabilityToWork(!availableToWork)}
                            >
                                {availableToWork ? 'No estaré activo' : 'Activarme'}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

export default Sidebar;
