import React, { Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
// images import
import man from '../../../assets/images/dashboard/man.jpeg';
import { removeItem, keyLocalStorage } from '../../../config/localStorage';
import { useStateValue } from '../../../store';

const User_menu = withRouter(({ history }) => {
    const [{}, dispatch] = useStateValue();
    const handleLogout = () => {
        removeItem(keyLocalStorage);
        dispatch({
            type: 'UNSET_USER',
        });
        history.push('/');
    };

    return (
        <Fragment>
            <li className="onhover-dropdown" style={{ width: 'auto' }}>
                <div className="media align-items-center">
                    <img
                        className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
                        src={man}
                        alt="header-user"
                        style={{ border: '1px solid #ccc' }}
                    />
                    <div className="dotted-animation"></div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li>
                        <a onClick={handleLogout}>
                            <i className="fa fa-sign-out"></i>&nbsp;Salir
                        </a>
                    </li>
                </ul>
            </li>
        </Fragment>
    );
});

export default User_menu;
