import React, { useReducer, useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Loader from '../common/loader';
import { toast } from 'react-toastify';
import api from '../../config/axios';
import utils from '../../utils/miscUtils';
import utilStyles from '../../assets/styles/utils';
import { useStateValue } from '../../store';
import { getMessageForDeliveryOption } from './utils';

const { currency } = utils;

export const getTimeFormat = (time) => {
    return `${time.getHours()}:${time.getMinutes()}`;
};

const convertHourToShortFormat = (time) => {
    let hour = Number(time.split(':')[0]);
    let minutes = Number(time.split(':')[1]);
    const amPM = hour > 11 ? 'pm' : 'am';

    hour = hour > 12 ? hour - 12 : hour;
    minutes = minutes === 0 ? '00' : minutes;

    return `${hour}:${minutes} ${amPM}`;
};

const NewOrder = withRouter(({ history }) => {
    const [{ auth }] = useStateValue();
    const { handleSubmit, register, errors, setValue, clearError, getValues, watch } = useForm();
    const [options, setOptions] = useReducer((s, a) => ({ ...s, ...a }), {
        resolved: false,
        loading: false,
        loadingPost: false,
        deliveries: [],
        paymentMethods: [],
        settings: [],
        products: [],
        error: null,
    });
    const [client, setClient] = useReducer((s, a) => ({ ...s, ...a }), {
        resolved: false,
        loading: false,
        data: null,
        error: null,
    });
    const [deliveryMode, setDeliveryMode] = useState('express');
    const [useSubscription, setUseSubscription] = useState(false);
    const franchiseId = auth.user.franchiseId || null;
    const baseUrlClients = franchiseId ? '/client/franchise' : '/client';

    const fetchFranchise = async (lat, lng) => {
        try {
            const franchise = await api.get(`/franchise/location?lat=${lat}&lng=${lng}`);
            return franchise.data.data;
        } catch (e) {
            console.log(e);
        }
    };

    const fetchPaymentMethods = (franchiseId) => {
        return api.get(`/franchise/${franchiseId}/payment_method`);
    };

    const fetchDelivery = (franchiseId) => {
        const currentDate = new Date();
        const currentWeekDay = currentDate.getDay();
        const currentTime = getTimeFormat(currentDate);
        return api.get(
            `/franchise/${franchiseId}/schedule?weekday=${currentWeekDay}&time=${currentTime}`,
        );
    };

    const fetchProducts = (franchiseId) => {
        return api.get(`/franchise/${franchiseId}/products`);
    };

    const searchClients = async (clientName) => {
        try {
            const clients = await api.get(
                `${baseUrlClients}?page=0&name=${clientName}&franchiseId=${franchiseId}`,
            );
            return clients.data.data.rows;
        } catch (e) {
            return [];
        }
    };

    const fetchClient = async (id) => {
        setClient({
            ...client,
            resolved: false,
            loading: true,
        });
        setValue('addressId', null);
        try {
            const client = await api.get(`/client/${id}/profile`);
            if (client.data.success) {
                const addresses = await api.get(`/client/${id}/address`);
                const subscription = await api.get(`/client/${id}/subscription`);
                setClient({
                    loading: false,
                    resolved: true,
                    error: false,
                    data: {
                        ...client.data.data,
                        addresses: addresses.data.data.rows || null,
                        subscription:
                            subscription && subscription.data ? subscription.data.data : null,
                    },
                });
                if (addresses.data.data.rows.length === 1) {
                    handleSelectOption('addressId', addresses.data.data.rows[0]);
                }
            } else {
                setClient({
                    loading: false,
                    resolved: true,
                    error: false,
                    data: null,
                });
            }
        } catch (e) {
            setClient({
                loading: true,
                resolved: false,
                error: e,
                data: null,
            });
        }
    };

    const getValueQuantity = (products, field) => {
        const product = products.find((p) => p.field === field);

        if (!!product) {
            return product.quantity;
        }
        return 0;
    };

    const addRefill = (data) => {
        // delivery por defecto
        // es el único que retorna el backend
        // 26/08/2022
        const deliveryByDefault = options.deliveries[0];
        const productsRequest = options.products
            .filter((p) => p.quantity != 0)
            .map((p) => ({ id: p.id, quantity: p.quantity, field: p.field }));
        const dataRefill = {
            addressId: data.addressId.id,
            clientId: client.data.id,
            deliveryId: deliveryByDefault.id,
            paymentMethodId: data.paymentMethodId ? data.paymentMethodId.id : null,
            quantity: getValueQuantity(productsRequest, 'quantity'),
            quantity_new_bottles: getValueQuantity(productsRequest, 'quantity_new_bottles'),
            quantity_starterkit: getValueQuantity(productsRequest, 'quantity_starterkit'),
            quantity_dispenser: getValueQuantity(productsRequest, 'quantity_dispenser'),
            subscriptionId: useSubscription ? client.data.subscription.subscription.id : null,
            payment_confirmation_id:
                data.confirmationId && data.confirmationId !== ''
                    ? Number(data.confirmationId)
                    : null,
            payment_code_reference: null,
            delivery_time: data.delivery_time || null,
            products: productsRequest,
            requestedFrom: 'admin',
            changeCash: data.changeCash && data.changeCash !== '' ? Number(data.changeCash) : null,
            couponId: data.couponId || null,
            preNote: data.preNote || null,
        };

        if (
            dataRefill.quantity !== 0 ||
            dataRefill.quantity_new_bottles !== 0 ||
            dataRefill.quantity_starterkit !== 0 ||
            dataRefill.quantity_dispenser !== 0
        ) {
            setOptions({
                ...options,
                loadingPost: true,
            });
            api.post(`/purchase/v2`, dataRefill)
                .then((response) => {
                    setOptions({
                        ...options,
                        loadingPost: false,
                    });
                    if (response.data.success) {
                        toast.success('Pedido creado con éxito');
                        history.push(`/recargas/detalle/${response.data.data.purchaseInfo.id}`);
                    } else {
                        toast.error('Pedido no fue creada. Intenta de nuevo');
                    }
                })
                .catch((error) => {
                    setOptions({
                        ...options,
                        loadingPost: false,
                    });
                    toast.error('Ha ocurrido un error, intenta de nuevo.');
                });
        } else {
            toast.error('Selecciona la cantidad de al menos un Servicio/producto');
        }
    };

    const handleSelectOption = (field, value) => {
        setValue(field, value);
        clearError(field);

        if (field === 'addressId') {
            showInfoRecarga(value);
        }
    };

    const showInfoRecarga = async (address) => {
        const franchise = await fetchFranchise(address.latitude, address.longitude);

        setOptions({
            ...options,
            loading: true,
        });
        Promise.all([
            fetchPaymentMethods(franchise.franchiseId),
            fetchDelivery(franchise.franchiseId),
            fetchProducts(franchise.franchiseId),
        ]).then((values) => {
            setOptions({
                resolved: true,
                loading: false,
                paymentMethods: values[0].data.data,
                deliveries: values[1].data.data,
                products: values[2].data.data.map((p) => ({ ...p, quantity: 0 })),
                settings: franchise,
            });
        });
    };

    const promiseClients = async (inputValue) => {
        const clients = await searchClients(inputValue);
        let newClients = clients.map((client) => ({ value: client.id, label: client.name }));

        return newClients;
    };

    const getUnitAmount = (paymentMethod, product) => {
        switch (paymentMethod) {
            case 'COP':
                return Number(product.amount_cop);
            case 'USD':
                return Number(product.amount_usd);
            case 'VES':
                return Number(product.amount_usd * options.settings.usdves_value);
        }
    };

    const getWholesaleAmount = (paymentMethod, product) => {
        switch (paymentMethod) {
            case 'COP':
                return Number(product.amount_cop_wholesale);
            case 'USD':
                return Number(product.amount_usd_wholesale);
            case 'VES':
                return Number(product.amount_usd_wholesale * options.settings.usdves_value);
        }
    };

    const handleQuantity = (id, value) => {
        const newProducts = options.products.map((product) => {
            if (product.id === id) {
                return { ...product, quantity: value };
            } else {
                return product;
            }
        });

        setOptions({ ...options, products: newProducts });
    };

    const { paymentMethodId } = getValues();
    let quantityNewBottles = watch('quantity_new_bottles');
    let quantityStarterKit = watch('quantity_starterkit');
    let quantityDispenser = watch('quantity_dispenser');
    const addressId = watch('addressId');

    let totalAmount = 0;
    quantityNewBottles = !!quantityNewBottles ? quantityNewBottles : 0;
    quantityStarterKit = !!quantityStarterKit ? quantityStarterKit : 0;
    quantityDispenser = !!quantityDispenser ? quantityDispenser : 0;

    const getAmountProduct = (quantity, currency, product) => {
        if (quantity >= 5) {
            return getWholesaleAmount(currency, product);
        } else {
            return getUnitAmount(currency, product);
        }
    };
    totalAmount = options.products
        .filter((p) => p.quantity > 0)
        .reduce(
            (a, b) =>
                a +
                b.quantity *
                    getAmountProduct(
                        b.quantity,
                        paymentMethodId ? paymentMethodId.currency : 'COP',
                        b,
                    ),
            0,
        );

    const copyDeliveryOption =
        options.deliveries.length > 0 ? getMessageForDeliveryOption(options.deliveries[0]) : '';

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card mt-1">
                            <div className="card-body">
                                <div className="row product-adding">
                                    <div className="col-xl-8 offset-xl-2">
                                        <div className="needs-validation add-product-form mt-0">
                                            <div className="form form-label-center">
                                                <div className="form-group mb-2 row">
                                                    <label className="col-xl-3 col-md-3 col-xs-12 mb-0">
                                                        Cliente:
                                                    </label>
                                                    <div className="col-xl-6 col-md-6 col-xs-12 mb-0 p-0">
                                                        <AsyncSelect
                                                            placeholder="Escribe el nombre del cliente"
                                                            onChange={(item) =>
                                                                fetchClient(item.value)
                                                            }
                                                            loadOptions={promiseClients}
                                                            noOptionsMessage={() => ''}
                                                            loadingMessage={() => 'Buscando ...'}
                                                        />
                                                        {errors.clientName &&
                                                            errors.clientName.type ===
                                                                'required' && (
                                                                <p className="message-error-input">
                                                                    Cliente es obligatorio
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                {client.resolved && (
                                                    <div>
                                                        <div className="form-group mb-2 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">
                                                                Dirección de entrega:
                                                            </label>
                                                            <div className="col-xl-6 col-sm-7 p-0">
                                                                <Select
                                                                    name="addressId"
                                                                    placeholder="Selecciona la dirección"
                                                                    onChange={(value) =>
                                                                        handleSelectOption(
                                                                            'addressId',
                                                                            value,
                                                                        )
                                                                    }
                                                                    value={
                                                                        addressId
                                                                            ? {
                                                                                  value: addressId,
                                                                                  label: `${addressId.street1}, ${addressId.street2}`,
                                                                              }
                                                                            : null
                                                                    }
                                                                    options={client.data.addresses.map(
                                                                        (address) => {
                                                                            return {
                                                                                ...address,
                                                                                value: address,
                                                                                label: `${address.street1}, ${address.street2}`,
                                                                            };
                                                                        },
                                                                    )}
                                                                    ref={() =>
                                                                        register(
                                                                            {
                                                                                name: 'addressId',
                                                                                type: 'custom',
                                                                            },
                                                                            {
                                                                                required: true,
                                                                            },
                                                                        )
                                                                    }
                                                                />
                                                                {errors.addressId &&
                                                                    errors.addressId.type ===
                                                                        'required' && (
                                                                        <p className="message-error-input">
                                                                            Dirección es obligatoria
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}{' '}
                                                {client.loading && (
                                                    <div style={{ display: 'flex', height: 150 }}>
                                                        <Loader />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {addressId ? (
                        <div className="col-sm-12">
                            <div className="card">
                                {options.resolved ? (
                                    <div className="card-body">
                                        <div className="row product-adding">
                                            <div className="col-xl-8 offset-xl-2">
                                                <div className="needs-validation add-product-form mt-0">
                                                    <div className="form form-label-center">
                                                        <div className="form-group mb-2 row">
                                                            <div className="col-xl-9 col-sm-7 p-0">
                                                                {options.products.map(
                                                                    (product, index) => (
                                                                        <ProductItem
                                                                            key={`product-${index}`}
                                                                            product={product}
                                                                            index={index}
                                                                            paymentMethod={
                                                                                paymentMethodId
                                                                                    ? paymentMethodId.currency
                                                                                    : 'COP'
                                                                            }
                                                                            getUnitAmount={
                                                                                getUnitAmount
                                                                            }
                                                                            getWholesaleAmount={
                                                                                getWholesaleAmount
                                                                            }
                                                                            handleQuantity={
                                                                                handleQuantity
                                                                            }
                                                                        />
                                                                    ),
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', height: 150 }}>
                                        <Loader />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null}
                    {addressId && (
                        <div className="col-sm-12">
                            <div className="card">
                                {options.resolved && (
                                    <div className="card-body">
                                        <div className="row product-adding">
                                            <div className="col-xl-8 offset-xl-2">
                                                <div className="needs-validation add-product-form mt-0">
                                                    <div className="form form-label-center">
                                                        {client.data.subscription.active && (
                                                            <div className="form-group mb-2 row">
                                                                <label className="col-xl-3 col-sm-4 mb-0">
                                                                    Recargas a favor:{' '}
                                                                </label>
                                                                <div className="col-xl-6 col-sm-7 p-0">
                                                                    <p
                                                                        style={{
                                                                            fontSize: 20,
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        {
                                                                            client.data.subscription
                                                                                .availability
                                                                        }
                                                                    </p>
                                                                    <input
                                                                        type="checkbox"
                                                                        id="subscriptionCheck"
                                                                        name="contact"
                                                                        value="express"
                                                                        checked={useSubscription}
                                                                        onClick={() =>
                                                                            setUseSubscription(
                                                                                !useSubscription,
                                                                            )
                                                                        }
                                                                    />
                                                                    &nbsp;&nbsp;
                                                                    <label htmlFor="subscriptionCheck">
                                                                        Usar recargas a favor
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <hr />
                                                        <div className="form-group mb-2 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">
                                                                Método de pago:
                                                            </label>
                                                            <div className="col-xl-6 col-sm-7 p-0">
                                                                <Select
                                                                    name="paymentMethodId"
                                                                    placeholder="Selecciona aquí"
                                                                    onChange={(value) =>
                                                                        handleSelectOption(
                                                                            'paymentMethodId',
                                                                            value,
                                                                        )
                                                                    }
                                                                    options={options.paymentMethods.map(
                                                                        (paymentMethod) => {
                                                                            return {
                                                                                ...paymentMethod,
                                                                                value: paymentMethod.id,
                                                                                label: paymentMethod.name,
                                                                            };
                                                                        },
                                                                    )}
                                                                    isSearchable={false}
                                                                    ref={() =>
                                                                        register(
                                                                            {
                                                                                name: 'paymentMethodId',
                                                                                type: 'custom',
                                                                            },
                                                                            {
                                                                                required: true,
                                                                            },
                                                                        )
                                                                    }
                                                                />
                                                                {errors.paymentMethodId &&
                                                                    errors.paymentMethodId.type ===
                                                                        'required' && (
                                                                        <p className="message-error-input">
                                                                            Método de pago es
                                                                            obligatorio
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="form-group mb-2 row mt-2">
                                                            <label className="col-xl-3 col-sm-4 mb-0">
                                                                Hora de entrega:
                                                            </label>
                                                            <div className="col-xl-6 col-sm-7 p-0">
                                                                <input
                                                                    type="radio"
                                                                    id="expressChoice"
                                                                    name="contact"
                                                                    value="express"
                                                                    checked={
                                                                        deliveryMode === 'express'
                                                                    }
                                                                    onClick={() =>
                                                                        setDeliveryMode('express')
                                                                    }
                                                                />
                                                                &nbsp;&nbsp;
                                                                <label htmlFor="expressChoice">
                                                                    {copyDeliveryOption}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-2 row">
                                                            <div className="col-xl-3 col-sm-4 mb-0"></div>
                                                            <div className="col-xl-6 col-sm-7 p-0">
                                                                <input
                                                                    type="radio"
                                                                    id="scheduleChoice"
                                                                    name="contact"
                                                                    value="schedule"
                                                                    checked={
                                                                        deliveryMode === 'schedule'
                                                                    }
                                                                    onClick={() =>
                                                                        setDeliveryMode('schedule')
                                                                    }
                                                                />
                                                                &nbsp;&nbsp;
                                                                <label htmlFor="scheduleChoice">
                                                                    Programar hora
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-2 row">
                                                            <div className="col-xl-3 col-sm-4 mb-0"></div>
                                                            <div className="col-xl-6 col-sm-7 p-0">
                                                                <span style={{ fontSize: 14 }}>
                                                                    Horario laboral:{' '}
                                                                    {convertHourToShortFormat(
                                                                        options.deliveries[0]
                                                                            .startTime,
                                                                    )}{' '}
                                                                    -{' '}
                                                                    {convertHourToShortFormat(
                                                                        options.deliveries[0]
                                                                            .endTime,
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {deliveryMode === 'schedule' && (
                                                            <div className="form-group mb-2 row">
                                                                <label className="col-xl-3 col-sm-4 mb-0">
                                                                    Hora:
                                                                </label>
                                                                <div className="col-xl-4 col-sm-7 p-0">
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="Selecciona la hora"
                                                                        name="delivery_time"
                                                                        type="time"
                                                                        ref={register({
                                                                            required: false,
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        <hr />
                                                        {paymentMethodId &&
                                                            !paymentMethodId.accounts && (
                                                                <div className="form-group mb-2 row mt-2">
                                                                    <label className="col-xl-3 col-sm-4 mb-0">
                                                                        Billete a pagar (opcional):
                                                                    </label>
                                                                    <div className="col-xl-4 col-sm-7 p-0">
                                                                        <input
                                                                            className="form-control"
                                                                            placeholder="Ejm: 20.000 o 50.000"
                                                                            name="changeCash"
                                                                            type="text"
                                                                            inputMode="numeric"
                                                                            ref={register({
                                                                                required: false,
                                                                            })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        {/* Agregar botón de Aplicar cupón */}
                                                        {/* <div className="form-group mb-2 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">
                                                                Cupón de descuento:
                                                            </label>
                                                            <div className="col-xl-4 col-sm-7 p-0">
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Ejm: PRUEBADECODIGO"
                                                                    name="couponId"
                                                                    type="text"
                                                                    inputMode="numeric"
                                                                    ref={register({
                                                                        required: false,
                                                                    })}
                                                                />
                                                            </div>
                                                        </div> */}
                                                        {paymentMethodId &&
                                                            paymentMethodId.name ==
                                                                'Pago móvil (VES)' && (
                                                                <div className="form-group mb-2 row">
                                                                    <label className="col-xl-3 col-sm-4 mb-0">
                                                                        Últimos 5 dígitos pago
                                                                        móvil:
                                                                    </label>
                                                                    <div className="col-xl-4 col-sm-7 p-0">
                                                                        <input
                                                                            className="form-control"
                                                                            name="confirmationId"
                                                                            type="text"
                                                                            inputMode="numeric"
                                                                            ref={register({
                                                                                required: false,
                                                                            })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        {!auth.user.franchiseId && (
                                                            <div className="form-group mb-2 row">
                                                                <label className="col-xl-3 col-sm-4 mb-0">
                                                                    Cupón:
                                                                </label>
                                                                <div className="col-xl-4 col-sm-7 p-0">
                                                                    <input
                                                                        className="form-control"
                                                                        name="couponId"
                                                                        type="text"
                                                                        inputMode="numeric"
                                                                        ref={register({
                                                                            required: false,
                                                                        })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <hr />
                                                        <div className="form-group mb-2 row">
                                                            <label className="col-xl-3  col-md-3 col-xs-12 mb-0">
                                                                Nota Pre-entrega:
                                                            </label>
                                                            <div className="col-xl-6 col-md-6 col-xs-12 mb-0 p-0">
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Ejm: Dejar en la puerta, tocar el timbre, dejarlo con el vigilante"
                                                                    name="preNote"
                                                                    type="text"
                                                                    ref={register({
                                                                        required: false,
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <br />

                                                        {paymentMethodId && (
                                                            <div className="form-group mb-3 mt-4 row">
                                                                <label className="col-xl-3 col-sm-4 mb-0">
                                                                    Total a pagar:
                                                                </label>
                                                                <br />
                                                                <div className="col-xl-6 col-sm-7 p-0">
                                                                    <p
                                                                        className="m-0"
                                                                        style={{ fontSize: '2em' }}
                                                                    >
                                                                        <strong>
                                                                            {paymentMethodId.currency ||
                                                                                'COP'}{' '}
                                                                            {currency(totalAmount)}
                                                                        </strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 text-center mt-4 mb-3">
                                            {options.loadingPost ? (
                                                <div style={{ display: 'flex', height: 150 }}>
                                                    <Loader />
                                                </div>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-lg"
                                                    onClick={handleSubmit(addRefill)}
                                                >
                                                    Crear pedido
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
});

function ProductItem(props) {
    const { product, index, paymentMethod, getUnitAmount, getWholesaleAmount, handleQuantity } =
        props;
    let price = getUnitAmount(paymentMethod, product);

    const handleOnChangeQuantity = (action) => {
        let qty = product.quantity;
        if (action === 'sum') {
            qty += 1;
        } else {
            qty -= 1;
        }
        handleQuantity(product.id, qty);
    };

    const isRefill = String(product.name).match(/Recarga/gi);

    if (isRefill && product.quantity >= 5) {
        price = getWholesaleAmount(paymentMethod, product);
    }
    return (
        <div
            key={`product-${index}`}
            style={{
                ...utilStyles.displayRowBetween,
                marginBottom: 16,
            }}
        >
            <div
                style={{
                    width: '50%',
                }}
            >
                <p
                    style={{
                        fontSize: 18,
                        margin: 0,
                    }}
                >
                    <strong>{product.name}</strong>
                </p>
                <p
                    style={{
                        fontSize: 16,
                        margin: 0,
                    }}
                >
                    {paymentMethod} {currency(price)}
                </p>
            </div>
            <div
                style={{
                    width: '45%',
                }}
            >
                {product.quantity > 0 ? (
                    <div style={utilStyles.displayRowEnd}>
                        <button
                            type="button"
                            className="btn btn-sm btn-outline"
                            onClick={() => handleOnChangeQuantity('sub')}
                        >
                            -
                        </button>
                        &nbsp;
                        <p className="m-0" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                            {product.quantity}
                        </p>
                        &nbsp;
                        <button
                            type="button"
                            className="btn btn-sm btn-outline btn-success"
                            onClick={() => handleOnChangeQuantity('sum')}
                        >
                            +
                        </button>
                    </div>
                ) : (
                    <div style={utilStyles.displayRowEnd}>
                        <button
                            type="button"
                            className="btn btn-sm btn-outline btn-success"
                            onClick={() => handleOnChangeQuantity('sum')}
                        >
                            <i className="fa fa-shopping-cart"></i>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default NewOrder;
