import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Breadcrumb from '../common/breadcrumb';
import Loader from '../common/loader';
import api from '../../config/axios';
import utils from '../../utils/miscUtils';
import { date, time } from '../../utils/date';
import { toast } from 'react-toastify';

const { currency } = utils;

function SubscriptionDetail(props) {
    const subscriptionId = props.match.params.id;
    const [loaded, setLoaded] = useState(false);
    const [subscription, setSubscription] = useState([]);
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        Promise.all([fetchSubscription(), fetchSettings()]).then(response => {
            setLoaded(true);
        });
    }, []);

    const fetchSubscription = async () => {
        const { data } = await api.get(`/subscription/${subscriptionId}`);
        const subscription = data.data;

        setSubscription(subscription);
        return;
    };

    const fetchSettings = async () => {
        const { data } = await api.get(`/settings`);
        const settings = data.data[0];

        setSettings(settings);
        return;
    };

    const confirmPayment = async () => {
        try {
            const { data } = await api.put(
                `client/${subscription.client.id}/subscription/${subscription.id}?status=activo`,
            );
            fetchSubscription();
            toast('Pago confirmado. Se ha enviado una notificación al cliente.');
        } catch (error) {
            toast('Ha ocurrido un error. Intente de nuevo');
        }
    };

    const rejectPayment = async () => {
        try {
            const { data } = await api.put(
                `client/${subscription.client.id}/subscription/${subscription.id}?status=pago_no_confirmado`,
            );
            fetchSubscription();
            toast('Pago rechazado. Se ha enviado una notificación al cliente.');
        } catch (error) {
            toast('Ha ocurrido un error. Intente de nuevo');
        }
    };

    const renderBadge = status => {
        switch (status) {
            case 'pago_por_confirmar':
                return (
                    <span style={{ fontSize: 16 }} className="badge badge-warning">
                        Pago por Confirmar
                    </span>
                );
            case 'pago_no_confirmado':
                return (
                    <span style={{ fontSize: 16 }} className="badge badge-danger">
                        Pago no confirmado
                    </span>
                );
            case 'activo':
                return (
                    <span style={{ fontSize: 16 }} className="badge badge-success">
                        Activo
                    </span>
                );
            case 'finalizado':
                return (
                    <span style={{ fontSize: 16 }} className="badge badge-danger">
                        Finalizado
                    </span>
                );
            default:
                return null;
        }
    };

    if (!loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    const total = subscription.quantity * Number(subscription.unity_amount_refill);
    return (
        <Fragment>
            <Breadcrumb title="Detalle de subscripcion" />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid container-order-detail">
                <div className="mb-4">{renderBadge(subscription.status)}</div>
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="clearfix"></div>
                                <h3>Información de cliente</h3>
                                <hr />
                                <p>
                                    <strong>{subscription.client.id}</strong>
                                </p>
                                <Link
                                    to={`${process.env.PUBLIC_URL}/clientes/${subscription.client.id}`}
                                    style={{ fontSize: '1em' }}
                                >
                                    {subscription.client.name}
                                </Link>
                                <p>{subscription.client.phone_number}</p>
                                <p>{subscription.client.email}</p>
                                <p style={{ textAlign: 'right' }}>
                                    Cliente desde el{' '}
                                    <strong>{date(subscription.client.createdAt)}</strong>
                                </p>
                                <br />
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h3 className="mt-4">Subscripción</h3>
                                <hr />
                                <div>
                                    <p style={{ fontSize: 16 }}>
                                        <strong>
                                            Recargas disponibles: {subscription.availability}{' '}
                                            recargas
                                        </strong>
                                    </p>
                                    <p>Recarga inicial: {subscription.quantity} recargas</p>
                                    <p>Fecha de inicio: {date(subscription.start_date)}</p>
                                    <p>Fecha de expiración: {date(subscription.expiration_date)}</p>
                                    <p>Nota: {subscription.note}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="clearfix"></div>
                                <h3>
                                    Resumen de subscripción <span>{subscription.id}</span>
                                </h3>
                                <hr />
                                <div className="order-box">
                                    <div className="title-box">
                                        <h4>Item</h4>
                                        <h4>Valor</h4>
                                    </div>
                                    <ul className="sub-total">
                                        <li
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div className="description-item-checkout">
                                                <div>
                                                    <p>
                                                        <strong style={{ fontSize: 18 }}>
                                                            {subscription.quantity}
                                                        </strong>
                                                         Recarga(s)
                                                        {subscription.quantity > 1
                                                            ? ` x ${subscription.paymentMethod.currency} ${subscription.unity_amount_refill}`
                                                            : null}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="total-item-checkout">
                                                <strong>
                                                    {subscription.paymentMethod.currency}{' '}
                                                    {currency(
                                                        subscription.quantity *
                                                            Number(
                                                                subscription.unity_amount_refill,
                                                            ),
                                                    )}
                                                </strong>
                                            </div>
                                        </li>
                                        <hr />
                                        <li>
                                            <h4>Total</h4>
                                            <strong className="count">
                                                {subscription.paymentMethod.currency}{' '}
                                                {currency(total)}
                                            </strong>
                                        </li>
                                    </ul>
                                    <p style={{ textAlign: 'right' }}>
                                        Fecha de pedido:{' '}
                                        <span>
                                            {date(subscription.createdAt)}{' '}
                                            {time(subscription.createdAt)}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {subscription.paymentMethod ? (
                            <div className="card">
                                <div className="card-body">
                                    <div className="clearfix"></div>
                                    <h3>Método de pago</h3>
                                    <hr />
                                    <div>
                                        <p>{subscription.paymentMethod.name}</p>
                                        <p>{subscription.paymentMethod.currency}</p>
                                    </div>
                                    {subscription.status === 'pago_por_confirmar' ? (
                                        <div className="mt-4 d-flex d-flex justify-content-between">
                                            <button
                                                className="btn btn-success"
                                                onClick={confirmPayment}
                                            >
                                                Confirmar pago
                                            </button>
                                            <button
                                                className="btn btn-danger"
                                                onClick={rejectPayment}
                                            >
                                                Rechazar pedido
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    );
}

export default SubscriptionDetail;
