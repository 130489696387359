import React, { useEffect, useState, useReducer, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';

import Breadcrumb from '../common/breadcrumb';
import api from '../../config/axios';
import Loader from '../common/loader';

const FranchiseDetail = withRouter((props) => {
    const { handleSubmit, register, errors, setValue } = useForm();
    const { history } = props;
    const franchiseId = props.match.params.id;

    const [stateFranchise, setStateFranchise] = useReducer((s, a) => ({ ...s, ...a }), {
        loading: true,
        resolved: false,
        data: null,
        settings: null,
        error: null,
    });

    useEffect(() => {
        initFetch();
    }, []);

    const initFetch = () => {
        Promise.all([fetchFranchise(franchiseId), fetchFranchiseSettings(franchiseId)]).then(
            (values) => {
                setStateFranchise({
                    loading: false,
                    resolved: true,
                    data: values[0],
                    settings: values[1],
                });
            },
        );
    };

    const fetchFranchise = async (franchiseId) => {
        const { data } = await api.get(`franchise/${franchiseId}`);
        const settings = data.data;

        return settings;
    };

    const fetchFranchiseSettings = async (franchiseId) => {
        const { data } = await api.get(`franchise/${franchiseId}/settings`);
        const settings = data.data[0];

        return settings;
    };

    const updateFranchiseSettings = async (franchiseId, data) => {
        const response = await api.put(`franchise/${franchiseId}/settings`, data);
        const settings = response.data;

        return settings;
    };

    const handleUpdate = async (data) => {
        setStateFranchise({
            ...stateFranchise,
            loading: true,
            resolved: false,
        });
        if (data.refill_cost_currency) {
            data.refill_cost_currency = data.refill_cost_currency.value;
        } else {
            data.refill_cost_currency = settings.refill_cost_currency;
        }
        data.refill_cost = Number(data.refill_cost);
        try {
            await updateFranchiseSettings(franchiseId, data);
            initFetch();
            toast.success('Configuración de franquicia modificada con éxito');
        } catch (e) {
            setStateFranchise({
                ...stateFranchise,
                loading: false,
                resolved: true,
            });
            toast.error('Ha ocurrido un error. Intenta de nuevo.');
        }
    };

    if (stateFranchise.loading)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );

    const { data: franchise, settings } = stateFranchise;

    return (
        <Fragment>
            <Breadcrumb title="Detalle franquicia" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form className="needs-validation user-add" noValidate="">
                                    <h4>Información franquicia</h4>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span></span> ID Franquicia:
                                        </label>
                                        <strong className=" col-xl-8 col-md-7">
                                            {franchise.id}
                                        </strong>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Nombre
                                        </label>
                                        <input
                                            className="form-control col-xl-8 col-md-7"
                                            defaultValue={franchise.name}
                                            id="validationCustom0"
                                            type="text"
                                            required=""
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Address
                                        </label>
                                        <input
                                            className="form-control col-xl-8 col-md-7"
                                            id="validationCustom1"
                                            defaultValue={franchise.address}
                                            type="address"
                                            required=""
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Teléfono
                                        </label>
                                        <input
                                            className="form-control col-xl-8 col-md-7"
                                            defaultValue={franchise.phone}
                                            id="phone"
                                            type="text"
                                            required=""
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Expo token
                                        </label>
                                        <input
                                            className="form-control col-xl-8 col-md-7"
                                            defaultValue={franchise.expoToken}
                                            id="expoToken"
                                            type="text"
                                            required=""
                                            disabled
                                        />
                                    </div>
                                </form>
                                <div className="needs-validation user-add mt-5">
                                    <h4>Valores de configuración</h4>

                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Cambio USD / VES
                                        </label>
                                        <input
                                            className="form-control col-xl-8 col-md-7"
                                            defaultValue={settings.usdves_value}
                                            id="usdves_value"
                                            name="usdves_value"
                                            type="number"
                                            ref={register({
                                                required: true,
                                                defaultValue: settings.usdves_value,
                                            })}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-xl-3 col-md-4">
                                            <span>*</span> Costo de recarga
                                        </label>
                                        <div className="col-xl-2 col-3 p-0">
                                            <Select
                                                name="refill_cost_currency"
                                                defaultValue={{
                                                    value: settings.refill_cost_currency,
                                                    label: settings.refill_cost_currency,
                                                }}
                                                placeholder="Moneda"
                                                onChange={(value) =>
                                                    setValue('refill_cost_currency', value)
                                                }
                                                options={[
                                                    { value: 'COP', label: 'COP' },
                                                    { value: 'USD', label: 'USD' },
                                                    { value: 'VES', label: 'VES' },
                                                ]}
                                                ref={() =>
                                                    register(
                                                        {
                                                            name: 'refill_cost_currency',
                                                            type: 'custom',
                                                        },
                                                        {
                                                            required: false,
                                                        },
                                                    )
                                                }
                                            />
                                        </div>
                                        <input
                                            className="form-control col-xl-6 col-4"
                                            defaultValue={settings.refill_cost}
                                            id="refill_cost"
                                            name="refill_cost"
                                            type="number"
                                            ref={register({
                                                required: true,
                                                defaultValue: settings.refill_cost,
                                            })}
                                        />
                                    </div>
                                    <div className="col-sm-12 text-center mt-5 mb-3">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={handleSubmit(handleUpdate)}
                                        >
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});

export default FranchiseDetail;
