import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import User_menu from './user-menu';
import { AlignLeft, MoreHorizontal } from 'react-feather';

//images
import logo from '../../../assets/images/dashboard/main-logo.png';

export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebar: true,
            rightSidebar: true,
            navMenus: false,
        };
    }

    componentDidMount() {
        if (window.innerWidth < 800) {
            this.openCloseSidebar();
        }
    }

    toggle() {
        this.setState((prevState) => ({
            navMenus: !prevState.navMenus,
        }));
    }
    showRightSidebar = () => {
        if (this.state.rightSidebar) {
            this.setState({ rightSidebar: false });
            document.querySelector('.right-sidebar').classList.add('show');
        } else {
            this.setState({ rightSidebar: true });
            document.querySelector('.right-sidebar').classList.remove('show');
        }
    };
    goFull = () => {
        if (
            (document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)
        ) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    };

    openCloseSidebar = () => {
        if (this.props.sidebar) {
            this.setState({ sidebar: false });
            this.props.setSidebar(false);
        } else {
            this.setState({ sidebar: true });
            this.props.setSidebar(true);
        }
    };
    render() {
        return (
            <Fragment>
                {/* open */}
                <div className="page-main-header ">
                    <div className="main-header-right row">
                        <div className="mobile-sidebar">
                            <div className="media-body text-right switch-sm">
                                <label className="switch">
                                    <a onClick={this.openCloseSidebar}>
                                        <AlignLeft />
                                    </a>
                                </label>
                            </div>
                        </div>
                        <div className="main-header-left d-lg-none">
                            <div className="logo-wrapper">
                                <Link to="/">
                                    <img className="blur-up lazyloaded" src={logo} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="nav-right">
                            <ul
                                className={
                                    'd-flex justify-content-end nav-menus ' +
                                    (this.state.navMenus ? 'open' : '')
                                }
                            >
                                <User_menu />
                            </ul>
                            <div
                                className="d-lg-none mobile-toggle pull-right"
                                onClick={() => this.toggle()}
                            >
                                <MoreHorizontal />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Header;
