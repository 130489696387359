export const initialStateAuth = {
    user: null,
};

export default function authReducer(state, action) {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.payload,
            };
        case 'UNSET_USER':
            return {
                ...state,
                user: null,
            };
        default:
            return state;
    }
}
