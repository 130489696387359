export function date(value) {
    const formatDate = value.split('T')[0];
    const year = formatDate.split('-')[0];
    const month = formatDate.split('-')[1];
    const day = formatDate.split('-')[2];

    return `${day}-${month}-${year}`;
}

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function time(value) {
    const date = new Date(value);
    const newFormat = formatAMPM(date);

    return newFormat;
}

export function dateQuery(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month}-${day}`;
}

export const getTimeNowVenezuela = () => {
    var currentDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours() - 4,
        new Date().getMinutes(),
    );
    return currentDate;
};

export const timeCloseWorkday = () => {
    // 21 - 4 (GMT - 04:00) = 17 horas
    const fullDate = getTimeNowVenezuela();
    const date = new Date(
        Date.UTC(fullDate.getFullYear(), fullDate.getMonth(), fullDate.getUTCDate(), 17, 0, 0),
    );

    return date;
};

export const timeOpenWorkday = () => {
    // 12 - 4 (GMT - 07:00) = 08 horas
    const fullDate = getTimeNowVenezuela();
    const date = new Date(
        Date.UTC(fullDate.getFullYear(), fullDate.getMonth(), fullDate.getUTCDate(), 8, 0, 0),
    );

    return date;
};
