import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import Breadcrumb from '../common/breadcrumb';
import Loader from '../common/loader';
import api from '../../config/axios';
import utils from '../../utils/miscUtils';

const columnsDataTable = [
    {
        Header: <b>Fecha</b>,
        accessor: 'createdAt',
        Cell: null,
        style: {
            textAlign: 'center',
        },
        width: 120,
    },
    {
        Header: <b>Orden</b>,
        accessor: 'orderId',
        Cell: null,
        style: {
            textAlign: 'center',
        },
        width: 120,
    },
    {
        Header: <b>Descripcion</b>,
        accessor: 'description',
        Cell: null,
        style: {
            textAlign: 'left',
        },
        sortable: false,
    },
    {
        Header: <b>Opción</b>,
        id: 'delete',
        accessor: (str) => 'delete',
        Cell: (row) => (
            <div>
                <span onClick={() => row.original.actionDelete(row.original)}>
                    <i
                        className="fa fa-check"
                        style={{
                            width: 35,
                            fontSize: 20,
                            padding: 11,
                            color: 'green',
                            cursor: 'pointer',
                        }}
                    ></i>
                </span>
            </div>
        ),
        style: {
            textAlign: 'center',
        },
        sortable: false,
        width: 120,
    },
];

function News(props) {
    const [news, setNews] = useState({
        list: [],
        loaded: false,
        pagination: {
            page: 1,
        },
    });
    const [modalDelete, setModalDelete] = useState({
        visible: false,
        data: {},
    });

    useEffect(() => {
        fetchNews(news.pagination.page);
    }, []);

    const fetchNews = async () => {
        const { data } = await api.get(`/news`);
        const news = parseToDatatable(data.data);

        setNews({
            loaded: true,
            list: news,
        });
    };

    const deleteNews = () => {
        api.delete(`/news/${modalDelete.data.id}`)
            .then(() => {
                toast('Novedad eliminada con éxito');
                setModalDelete({
                    visible: false,
                    data: {},
                });
                fetchNews();
            })
            .catch((error) => toast.success('Error eliminando la novedad, intenta nuevamente.'));
    };

    const handleModalDelete = (data) => {
        setModalDelete({
            visible: true,
            data,
        });
    };

    const amountCoupon = (coupon) => {
        if (coupon.type === 'PERCENTAGE') {
            return `${Number(coupon.amount_usd)} %`;
        } else if (coupon.type === 'AMOUNT_FIXED') {
            return `${coupon.amount_usd} USD  ${coupon.amount_cop} COP`;
        }
    };

    const parseToDatatable = (array) => {
        const parsed = array.map((item) => {
            return {
                id: item.id,
                description: (
                    <div>
                        <p>
                            {item.name}, {item.street1}
                        </p>
                        {item.description}
                    </div>
                ),
                orderId: (
                    <Link to={`${process.env.PUBLIC_URL}/recargas/detalle/${item.orderId}`}>
                        {item.orderId}
                    </Link>
                ),
                createdAt: `${moment(item.createdAt).format(utils.formatDate)}`,
                actionDelete: handleModalDelete,
            };
        });
        return parsed;
    };

    const handlePage = (page) => {
        fetchNews();
    };

    if (!news.loaded)
        return (
            <div style={{ display: 'flex', height: '50vh' }}>
                <Loader />
            </div>
        );
    return (
        <Fragment>
            <Breadcrumb title="Novedades" />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="clearfix"></div>
                                <div className="table-boxes">
                                    <ReactTable
                                        columns={columnsDataTable}
                                        className="-striped -highlight"
                                        multiSelectOption={false}
                                        data={news.list}
                                        page={1}
                                        defaultPageSize={columnsDataTable.length}
                                        showPageSizeOptions={false}
                                        onPageChange={(index) => handlePage(index + 1)}
                                        manual
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDelete
                visible={modalDelete.visible}
                handleSubmit={deleteNews}
                handleClose={() => setModalDelete({ visible: false, data: {} })}
            />
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    );
}

const ModalDelete = (props) => {
    const { visible, handleSubmit, handleClose } = props;
    return (
        <Modal open={visible} onClose={handleClose}>
            <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Eliminar novedad
                </h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <p>Estas seguro de eliminar esta novedad?</p>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                    Si
                </button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                    No
                </button>
            </div>
        </Modal>
    );
};

export default News;
